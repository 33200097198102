<template>
  <div class="">
    <div class="card-body" v-if="saleDefinition.definition">
      <ul class="nav nav-tabs" role="tablist">
        <li class="nav-item" v-if="saleDefinition.definition.registration">
          <a
            href="#registration"
            class="nav-link active"
            data-toggle="tab"
            id="registration-tab"
            role="tab"
            aria-controls="registration"
            aria-selected="true"
            ><i class="fa fa-registered"></i>&nbsp;&nbsp;Registration</a
          >
        </li>
        <li
          class="nav-item"
          v-if="saleDefinition.definition.registration"
        >
          <a
            href="#registrationnameservers"
            class="nav-link"
            data-toggle="tab"
            id="registrationnameservers-tab"
            role="tab"
            aria-controls="registrationnameservers"
            aria-selected="true"
            ><i class="fa fa-cogs"></i>&nbsp;&nbsp;Nameservers</a
          >
        </li>
       
        <li class="nav-item" v-if="saleDefinition.definition.registration">
          <a
            href="#contacts"
            class="nav-link"
            data-toggle="tab"
            id="contacts-tab"
            role="tab"
            aria-controls="contacts"
            aria-selected="true"
            ><i class="fa fa-drivers-license"></i>&nbsp;&nbsp;Contacts</a
          >
        </li>
        <li class="nav-item" v-if="saleDefinition.definition.hosting">
          <a
            href="#info"
            class="nav-link"
            :class="{ active: !saleDefinition.definition.registration }"
            data-toggle="tab"
            id="info-tab"
            aria-controls="info"
            aria-selected="true"
          >
            <i class="fa fa-cog"></i>&nbsp;&nbsp;Domain
          </a>
        </li>
         <li class="nav-item" v-if="saleDefinition.definition.hosting">
          <a
            href="#hostingnameservers"
            class="nav-link"
            data-toggle="tab"
            id="hostingnameservers-tab"
            role="tab"
            aria-controls="hostingnameservers"
            aria-selected="true"
          >
            <i class="fa fa-drivers-license"></i>&nbsp;&nbsp;NS Records
          </a>
        </li>

        <li class="nav-item" v-if="saleDefinition.definition.hosting">
          <a
            href="#hosts"
            class="nav-link"
            data-toggle="tab"
            id="hosts-tab"
            aria-controls="hosts"
            aria-selected="true"
          >
            <i class="fa fa-cloud"></i>&nbsp;&nbsp;Hosts
          </a>
        </li>     
      </ul>

      <form class="tab-content" v-on:submit.prevent="saveSale()">
        <div
          v-if="saleDefinition.definition.registration"
          class="tab-pane fade show active"
          id="registration"
          role="tabpanel"
          aria-labelledby="registration-tab"
        >
          <div class="card-body">
            <div class="row">
              <div class="col-md-8"> 
                <div class="row">
                  <div class="form-group d-flex align-items-baseline col-md-12">
                    <label class="col-md-3">Domain Name: </label>
                    <input
                      type="text"
                      class="form-control-plaintext"
                      disabled
                      v-model="saleDefinition.definition.registration.name"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="form-group d-flex align-items-baseline col-md-12">
                    <label class="col-md-3">Registration Date: </label>
                    <input
                      type="text"
                      class="form-control-plaintext"
                      disabled
                      :value="registrationDate"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="form-group d-flex align-items-baseline col-md-12">
                    <label class="col-md-3">Expiry Date: </label>
                    <input
                      type="text"
                      class="form-control-plaintext"
                      disabled
                      :value="expiryDate"
                    />
                  </div>
                </div>

                <div class="row">
                  <div class="form-group d-flex align-items-baseline col-md-12">
                    <label class="col-md-3">Locked: </label>
                    <toggle-button
                      disabled
                      name="locked"
                      :width="100"
                      class="text"
                      :color="{ checked: '#7DCE94', unchecked: '#82C7EB' }"
                      :labels="{ checked: 'Yes', unchecked: 'No' }"
                      v-model="saleDefinition.definition.registration.isLocked"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="form-group d-flex col-md-12">
                    <label class="col-md-3">Auto Renew: </label>
                    <toggle-button
                      disabled
                      name="renew"
                      :width="100"
                      class="text"
                      :color="{ checked: '#7DCE94', unchecked: '#82C7EB' }"
                      :labels="{ checked: 'Yes', unchecked: 'No' }"
                      v-model="saleDefinition.definition.registration.autoRenew"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <DomainRegistrationPackage
                  :domainPackage="
                    saleDefinition.definition.registration.domainRegistrationPackage
                  "
                /> 
              </div>
            </div>
          </div>
        </div>

         <!-- contacts section -->
        <div
          class="tab-pane"
          v-if="saleDefinition.definition.registration"
          id="contacts"
          role="tabpanel"
          aria-labelledby="contacts-tab"
        >
          <div class="">
            <div class="card-body">
              <div class="row">
                <div class="col-md-3">
                  <div class="card border-primary">
                    <h4 class="card-header border-bottom">Admin</h4>
                    <DomainRegistrationContact
                      :contact="
                        saleDefinition.definition.registration.adminRole.adminContact
                      "
                      :canEdit="false"
                      @saveContact="saveContact"
                    />
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="card border-primary">
                    <h4 class="card-header border-bottom">Registrant</h4>
                    <DomainRegistrationContact
                      :contact="
                        saleDefinition.definition.registration.registrantRole.registrantContact
                      "
                      :canEdit="false"
                      @saveContact="saveContact"
                    />
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="card border-primary">
                    <h4 class="card-header border-bottom">Billing</h4>
                    <DomainRegistrationContact
                      :contact="
                        saleDefinition.definition.registration.billingRole.billingContact
                      "
                      :canEdit="false"
                      @saveContact="saveContact"
                    />
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="card border-primary">
                    <h4 class="card-header border-bottom">Tech</h4>
                    <DomainRegistrationContact
                      :contact="
                        saleDefinition.definition.registration.technicalRole.technicalContact
                      "
                      :canEdit="false"
                      @saveContact="saveContact"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- contacts section -->       

        <!-- hosting info section -->
        <div
          class="tab-pane"
          v-if="saleDefinition.definition.hosting"
          :class="{
            'fade show active': !saleDefinition.definition.registration,
          }"
          id="info"
          role="tabpanel"
          aria-labelledby="info-tab"
        >
        <div class="card-body">
          <div class="row">
            <div class="col-md-8">
              <div class="row">
                <div class="form-group d-flex align-items-baseline col-md-12">
                  <label class="col-md-3">Name: </label>
                  <input
                    type="text"
                    class="form-control-plaintext"
                    disabled
                    v-model="saleDefinition.definition.hosting.name"
                  />
                </div>
              </div>
              <div class="row">
                <div class="form-group d-flex align-items-baseline col-md-12">
                  <label class="col-md-3">Minimum TTL: </label>
                  <input
                    type="text"
                    class="form-control-plaintext"
                    disabled
                    v-model="saleDefinition.definition.hosting.minimumTtl"
                  />
                </div>
              </div>
            </div>
            </div>
          </div>
          <modal
            :title="modalTitle"
            ref="addHostingNameServerModal"
            @save="saveHostingNameServer()"
          >
            <form>
              <div class="row">
                <div
                  class="
                    form-group
                    col-md-12
                    d-flex
                    align-items-baseline
                    justify-content-center
                  "
                >
                  <label class="col-md-2">Name:</label>
                  <div class="input-group col-md-10">
                    <input
                      placeholder="Enter Name"
                      type="text"
                      v-model="newHostingNameserver.name"
                      class="form-control"
                    />
                    <div class="input-group-append">
                      <span class="input-group-text"
                        >.{{ saleDefinition.definition.hosting.name }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div
                  class="
                    form-group
                    col-md-12
                    d-flex
                    align-items-baseline
                    justify-content-center
                  "
                >
                  <label class="col-md-2">Destination:</label>
                  <div class="input-group col-md-10">
                    <input
                      placeholder="Enter Destination"
                      type="text"
                      v-model="newHostingNameserver.destination"
                      class="form-control"
                    />
                  </div>
                </div>
              </div>
            </form>
          </modal>

          <hr />
          <div class="row">
            <div class="col-md-12">
              <h4>Default NS Records</h4>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <table class="table table-bordered">
                <thead>
                  <th>TTL</th>
                  <th>Destination</th>
                </thead>
                <tbody
                  v-if="
                    saleDefinition.definition.hosting.defaultNsHosts.length > 0
                  "
                >
                  <tr
                    v-for="host in saleDefinition.definition.hosting
                      .defaultNsHosts"
                    :key="host.hostNumber"
                  >
                    <td>{{ host.ttl }}</td>
                    <td style="word-break: break-word">{{ host.destination }}</td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="4">No NS Records</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

        </div>
        <!-- hosting section hosts -->
        <div
          class="tab-pane"
          v-if="saleDefinition.definition.hosting"
          id="hosts"
          role="tabpanel"
          aria-labelledby="hosts-tab"
        >
          <div class="row row-spacing">
            <div class="col-md-12 mb-3">
              <button type="button" @click="addHost()" class="btn btn-info" v-if="staffOrClientAccess(productRestriction)">
                Add Host record
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <table class="table table-bordered">
                <thead>
                  <th>Name</th>
                  <th>Type</th>
                  <th>TTL</th>
                  <th>Destination</th>
                  <th v-if="staffOrClientAccess(productRestriction)">Actions</th>
                </thead>
                <tbody
                  v-if="saleDefinition.definition.hosting.hosts.length > 0"
                >
                  <tr
                    v-for="host in saleDefinition.definition.hosting.hosts"
                    :key="host.hostNumber"
                  >
                    <td>{{ host.name }}</td>
                    <td>{{ host.type }}</td>
                    <td>{{ host.ttl }}</td>
                    <td class="wrap-text">{{ host.destination }}</td>
                    <td v-if="staffOrClientAccess(productRestriction)" style="text-align:center">
                      <button
                        v-tooltip="'Edit Host'"
                        type="button"
                        @click="editHost(host)"
                        class="btn btn-sm btn-info m-1"
                      >
                        <em class="fa fa-edit fa-fw"></em>
                      </button>
                      <button
                        v-tooltip="'Delete Host'"
                        type="button"
                        @click="deleteHost(host)"
                        class="btn btn-sm btn-primary"
                      >
                        <em class="fa fa-trash fa-fw"></em>
                      </button>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="5">No Hosts</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <modal
            :title="modalTitle"
            ref="addHostModal"
            size="modal-lg"
            @save="saveHost()"
          >
            <form>
              <div class="row">
                <div
                  class="
                    form-group
                    col-md-12
                    d-flex
                    align-items-baseline
                    justify-content-center
                  "
                >
                  <label class="col-md-2">Name:</label>
                  <div class="input-group col-md-10">
                    <input
                      placeholder="Enter Name"
                      :disabled="modalTitle.includes('Edit')"
                      type="text"
                      v-model="host.name"
                      class="form-control"
                    />
                    <div class="input-group-append">
                      <span class="input-group-text"
                        >.{{ saleDefinition.definition.hosting.name }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div
                  class="
                    form-group
                    col-md-12
                    d-flex
                    align-items-baseline
                    justify-content-center
                  "
                >
                  <label class="col-md-2">Type:</label>
                  <v-select
                    :options="availableHostTypes"
                    ref="hostType"
                    placeholder="Select Type"
                    class="col-md-10"
                    v-model="host.type"
                    valueProp="name"
                    label="description"
                  ></v-select>
                </div>
              </div>
              <div class="row">
                <div
                  class="
                    form-group
                    col-md-12
                    d-flex
                    align-items-baseline
                    justify-content-center
                  "
                  v-if="modalType"
                >
                  <label class="col-md-2">Priority:</label>
                  <div class="col-md-10">
                    <input
                      type="number"
                      placeholder="Enter Priority"
                      v-model="host.priority"
                      class="form-control"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div
                  class="
                    form-group
                    col-md-12
                    d-flex
                    align-items-baseline
                    justify-content-center
                  "
                >
                  <label class="col-md-2">TTL:</label>
                  <div class="col-md-10">
                    <input
                      type="number"
                      min="0"
                      placeholder="Enter TTL"
                      v-model="host.ttl"
                      class="form-control"
                    />
                  </div>
                </div>
              </div>
              <div v-if="host.type == 'SRV'">
                <div class="row">
                  <div
                    class="
                      form-group
                      col-md-12
                      d-flex
                      align-items-baseline
                      justify-content-center
                    "
                  >
                    <label class="col-md-2">Destination:</label>
                    <div class="col-md-10">
                      <input
                        type="text"
                        v-model="host.destination"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" v-else>
                <div
                  class="
                    form-group
                    col-md-12
                    d-flex
                    align-items-baseline
                    justify-content-center
                  "
                >
                  <label class="col-md-2">Destination:</label>
                  <div class="col-md-10">
                    <input
                      type="text"
                      name="host"
                      placeholder="Enter Destination"
                      v-model="host.destination"
                      class="form-control"
                    />
                    <small v-if="host.type == 'TXT'"
                      >Quotations will be added automatically</small
                    >
                  </div>
                </div>
              </div>
            </form>
          </modal>
        </div>

        <!-- hosting section nameservers -->
        <div
          class="tab-pane"
          v-if="saleDefinition.definition.hosting"
          id="hostingnameservers"
          role="tabpanel"
          aria-labelledby="hostingnameservers-tab"
        >
          <div class="row">
            <div class="col-md-12">
              <table class="table table-bordered">
                <thead>
                  <th>Name</th>
                  <th>Type</th>
                  <th>TTL</th>
                  <th>Destination</th>
                </thead>
                <tbody
                  v-if="
                    nsHosts.length > 0
                  "
                >
                  <tr
                    v-for="host in nsHosts"
                    :key="host.hostNumber"
                  >
                    <td>{{ host.name }}</td>
                    <td>{{ host.type }}</td>
                    <td>{{ host.ttl }}</td>
                    <td style="word-break: break-word">{{ host.destination }}</td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="4">No NS records</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        
        <!-- nameservers for domain registration only -->
        <div
          class="tab-pane"
          id="registrationnameservers"
          v-if="saleDefinition.definition.registration"
          role="tabpanel"
          aria-labelledby="registrationnameservers-tab"
        >
          <div class="row row-spacing">
            <div class="col-md-12 mb-3" v-if="staffOrClientAccess(productRestriction)">
              <button
                type="button"
                @click="addRegistrationNameserver()"
                class="btn btn-info"
              >
                Add Nameserver
              </button>
            </div>
            <div class="col-md-12">
              <table class="table table-bordered">
                <thead>
                  <th>Destination</th>
                  <th v-if="staffOrClientAccess(productRestriction)">Actions</th>
                </thead>
                <tbody
                  v-if="saleDefinition.definition.registration.nameservers.length > 0"
                > 
                  <tr
                    v-for="nameserver in saleDefinition.definition.registration.nameservers"
                    :key="nameserver.nameserverNumber"
                  >
                    <td style="word-break: break-word">{{ nameserver.destination }}</td>
                    <td v-if="staffOrClientAccess(productRestriction)" style="text-align:center">
                      <button
                        v-tooltip="'Delete Registration Nameserver'"
                        @click="deleteRegistrationNameserver(nameserver)"
                        type="button"
                        class="btn btn-sm btn-primary"
                      >
                        <em class="fa fa-trash fa-fw"></em>
                      </button>
                    </td>
                  </tr> 
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="4">No Nameservers</td>
                  </tr>
                </tbody> 
              </table>
            </div>
          </div>
          <modal
            :title="modalTitle"
            ref="addRegistrationNameServerModal"
            @save="saveRegistrationNameServer()"
          >
            <form>
              <div class="row">
                <div
                  class="
                    form-group
                    col-md-12
                    d-flex
                    align-items-baseline
                    justify-content-center
                  "
                >
                  <label class="col-md-2">Destination:</label>
                  <div class="input-group col-md-10">
                    <input
                      placeholder="Enter Destination"
                      type="text"
                      v-model="registrationNameserver.destination"
                      class="form-control"
                    />
                  </div>
                </div>
              </div>
            </form>
          </modal>
        </div>



      </form>
    </div>
  </div>
</template>

<script>
import Modal from "@/components/GeneralComponents/Modal";
import DomainRegistrationPackage from "@/components/ObjParts/SingleConstructed/DomainRegistrationPackage";
import DomainRegistrationContact from "@/components/ObjParts/SingleCaptured/DomainRegistrationContact";
import SaleFunctions from "@/pages/Services/SaleFunctions";

export default {
  extends: SaleFunctions,
  components: {
    DomainRegistrationPackage,
    DomainRegistrationContact,
    Modal,
  },
  data() {
    return {
      saleDefinition: {
        sale: {},
        registration: {},
        supportingEnums: {},
      },
      availableHostTypes: [],
      registrationNameserver: {},
      newHostingNameserver: {},
      host: {},
      mxSrvTypes: [],
      hostTypes: [],
      nsHosts: [],
      modalTitle: "",
      productRestrictions: [],
      productRestriction: {},
    };
  },
  async mounted() {
    this.loadSale();
  },
  methods: {
    addRegistrationNameserver() {
      this.modalTitle = "Add Nameserver";
      this.registrationNameserver = this.saleDefinition.supportingObjs.nameservers;
      this.registrationNameserver.domainRegistrationNumber = this.saleDefinition.definition.registration.domainRegistrationNumber;
      this.$refs.addRegistrationNameServerModal.open();
    },
    addHostingNameserver() {
      this.modalTitle = "Add NS record";
      this.newHostingNameserver = this.saleDefinition.supportingObjs.hosts;
      this.newHostingNameserver.domainNumber = this.saleDefinition.definition.hosting.domainNumber;
      this.newHostingNameserver.type = "NS";
      this.newHostingNameserver.saleNumber = this.saleDefinition.sale.saleNumber;
      this.$refs.addHostingNameServerModal.open();
    },
    saveRegistrationNameServer() {
      this.$refs.addRegistrationNameServerModal.isLoading();
      this.$http
        .put(
          this.$config.aimsAPI +
            "sales/definition/" +
            this.saleDefinition.sale.saleNumber +
            "/obj/update/",
          this.registrationNameserver
        )
        .then(
          (response) => {
            this.saveSale();
            this.$refs.addRegistrationNameServerModal.close();
          },
          (error) => {
            console.error(error);
            this.showError("Error Adding Nameserver", error);
          }
        )
        .finally(() => {
          this.$refs.addRegistrationNameServerModal.isLoading();
        });
    },
    saveHostingNameServer() {
      this.$refs.addHostingNameServerModal.isLoading();
      this.$http
        .put(
          this.$config.aimsAPI +
            "sales/definition/" +
            this.saleDefinition.sale.saleNumber +
            "/obj/update",
          this.newHostingNameserver
        )
        .then(
          (response) => {
            this.saveSale();
            this.$refs.addHostingNameServerModal.close();
          },
          (error) => {
            console.error(error);
            this.showError("Error Adding Nameserver", error);
          }
        )
        .finally(() => {
          this.$refs.addHostingNameServerModal.isLoading();
        });
    },
    deleteRegistrationNameserver(nameserverToDelete) {
      this.$swal({
        title: "Are you sure you want to delete?",
        type: "warning",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          this.$http
            .post(
              this.$config.aimsAPI +
                "sales/definition/" +
                this.saleDefinition.sale.saleNumber +
                "/obj/delete/",
              nameserverToDelete
            )
            .then(
              (response) => {
                this.showSuccess(response.data.message);
                const index =
                  this.saleDefinition.definition.registration.nameservers.findIndex(
                    (ns) =>
                      ns.nameserverNumber ===
                      nameserverToDelete.nameserverNumber
                  );
                this.saleDefinition.definition.registration.nameservers.splice(
                  index,
                  1
                );
              },
              (error) => {
                console.error(error);
                this.showError("Error Deleting Nameserver", error);
              }
            );
        }
      });
    },
    saveContact(contactArray) {
      this.$http
        .put(
          this.$config.aimsAPI +
            "sales/definition/" +
            this.saleDefinition.sale.saleNumber +
            "/objlist/update/",
          contactArray
        )
        .then(
          (response) => {
            this.loadSale();
          },
          (error) => {
            console.error(error);
            this.showError("Error Saving Contact details", error);
          }
        );
    },
    loadSale() {
      const saleNumber = this.$route.params.saleNumber;
      this.getSaleDefinition(saleNumber).then((result) => {
        console.log(result.body);
        this.saleDefinition = result.body;
        if (this.saleDefinition.definition.hosting) {
          this.nsHosts = this.saleDefinition.definition.hosting
          .hosts.filter(
            (h) => h.type === "NS"
          );
        }
        this.getAllProductRestrictions();
      });
    },
    saveSale() {
      this.$http
        .put(
          this.$config.aimsAPI +
            "sales/definition/" +
            this.saleDefinition.sale.saleNumber +
            "/update",
          this.saleDefinition
        )
        .then(
          (response) => {
            this.showSuccess("Save Success");
            this.saleDefinition = response.data;

            if (this.saleDefinition.definition.hosting) {
                this.saleDefinition.supportingObjs.hosts.ttl = 0;
                this.saleDefinition.supportingObjs.hosts.version = 0;
            }
          },
          (error) => {
            console.error(error);
            this.showError("Error Saving SaleDefinition", error);
          }
        );
    },
    saleLabelChanged(label) {
      this.saleDefinition.sale.label = label;
      this.saveSale();
    },

    addHost() {
      this.modalTitle = "Add Host";
      this.host = {
        class: this.saleDefinition.supportingObjs.hosts.class,
        domainNumber: this.saleDefinition.definition.hosting.domainNumber,
      };
      this.availableHostTypes = this.saleDefinition.supportingEnums.Host.type;
      this.$refs.hostType.clearSelection();
      this.$refs.addHostModal.open();
    },
    saveHost() {
      let save = true;
      if ((this.host.type == "NS") && ((this.host.name === undefined) || (this.host.name === ""))) {
        save = false;
      }

      if (this.host.type == "TXT") {
        const firstChar = this.host.destination.substring(0, 1);
        const lastChar = this.host.destination.substring(
          this.host.destination.length - 1
        );

        if (firstChar != '"') {
          this.host.destination = '"' + this.host.destination;
        }
        if (lastChar != '"') {
          this.host.destination = this.host.destination + '"';
        }
      }

      if (save) {
        this.$refs.addHostModal.isLoading();
        if (this.host.objKey === 0) {
          this.$http
            .post(
              this.$config.aimsAPI +
                "sales/definition/" +
                this.saleDefinition.sale.saleNumber +
                "/obj/create",
              this.host
            )
            .then(
              (response) => {
                this.loadSale();
                this.$refs.addHostModal.close();
              },
              (error) => {
                console.error(error);
                this.showError("Error Saving Host", error);
              }
            )
            .finally(() => {
              this.$refs.addHostModal.isLoading();
            });
        } else {
          this.$http
            .put(
              this.$config.aimsAPI +
                "sales/definition/" +
                this.saleDefinition.sale.saleNumber +
                "/obj/update",
              this.host
            )
            .then(
              (response) => {
                this.loadSale();
                this.$refs.addHostModal.close();
              },
              (error) => {
                console.error(error);
                this.showError("Error Saving Host", error);
              }
            )
            .finally(() => {
              this.$refs.addHostModal.isLoading();
            });
        }
      } else {
        this.showWarningAlert("NS host record must have Name")
      }
    },
    editHost(host) {
      this.host = Object.assign({}, host);
      this.modalTitle = "Edit Host";
      this.$refs.addHostModal.open();
    },
    deleteHost(host) {
      this.$swal({
        title: "Are you sure you want to delete this host?",
        text: host.type + ": " + host.name + " " + host.destination,
        type: "warning",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          this.$http
            .post(
              this.$config.aimsAPI +
                "sales/definition/" +
                this.saleDefinition.sale.saleNumber +
                "/obj/delete/",
              host
            )
            .then(
              (response) => {
                this.showSuccess(response.data.message);
                const index =
                    this.saleDefinition.definition.hosting.hosts.findIndex(
                      (h) => h.hostNumber === host.hostNumber
                    );
                  this.saleDefinition.definition.hosting.hosts.splice(
                    index,
                    1
                  );
              },
              (error) => {
                console.error(error);
                this.showError("Error deleting host", error);
              }
            );
        }
      });
    },
    getAllProductRestrictions: function() {
      const hostUrl = this.$config.aimsAPI;

      this.$http.get(`${hostUrl}products/restrictions`).then(
        (response) => {
          this.productRestrictions = response.data;

          this.productRestrictions.filter((restriction) => {
            if (restriction.description.toLowerCase() === this.saleDefinition.product.clientAccessRestriction.toLowerCase()) {
              this.productRestriction = restriction;
            }
          });
        },
        (error) => {
          console.error(error);
          this.showError("Error fetch all product restrictions", error);
        }
      );
    }
  },
  computed: {
    modalType() {
      return (
        this.modalTitle == "Add MX/SRV Host" ||
        this.modalTitle == "Edit MX/SRV Host"
      );
    },
    registrationDate() {
      return this.dateFormat(
        this.saleDefinition.definition.registration.registrationDate
      );
    },
    expiryDate() {
      return this.dateFormat(
        this.saleDefinition.definition.registration.expiryDate
      );
    },
    isDisabled() {
      let hasRight = this.userContainsPermission(["STAFF", "TECHNICAL", "CLIENT_ADMIN"]);
      return (this.clientReadOnly && !hasRight); 
    }
  },
};
</script>

<style>
.text {
  font-size: 1rem !important;
}

.btn-info {
  background-color: #23b7e5 !important;
  border-color: #23b7e5 !important;
}

.btn-info:hover {
  color: rgb(234, 231, 225);
  background-color: rgb(22, 144, 182) !important;
  border-color: rgb(21, 136, 171) !important;
}

.wrap-text {
    word-wrap: break-word;
    word-break: break-word;
    white-space: normal;
    overflow-wrap: break-word;
    max-width: 400px;
}
</style>

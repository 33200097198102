<template>
  <div class="col-md-12">
    <div class="card card-default h-100">
      <div class="card-header">
        <div class="row">
          <div class="col-md-4">
            <button type="button" class="btn btn-primary" @click="cancelOrder">
              Cancel
            </button>
          </div>
        </div>
        <hr />
      </div>
      <div class="card-body">
        <component :is="selectedComponent"></component>
      </div>
    </div>
  </div>
</template>

<script>
import Octotel from "@/components/FttxOrders/Octotel.vue";
import Vumatel from "@/components/FttxOrders/Vumatel.vue";

export default {
  components: {
    octotel: Octotel,
    vumatel: Vumatel,
  },
  data() {
    return {
      selectedComponent: "",
    };
  },
  created() {
    if (this.$route.params.fnoName) {
      this.selectedComponent = this.$route.params.fnoName;
    }
    const pageTitle = `${this.$route.meta.title} - ${this.selectedComponent.charAt(0).toUpperCase()}${this.selectedComponent.slice(1)}`;
    if (this.userContainsPermission(["CLIENT"])) {
      this.$root.$emit("SET_CLIENT_PAGE_TITLE", pageTitle);
    } else {
      this.$root.$emit("SET_PAGE_TITLE", pageTitle);
      this.$root.$emit("SET_PAGE_TITLE_BADGES", []);
    }
  },
  methods: {
    cancelOrder: function () {
      if (this.$route.params.clientNumber) {
        this.$router.push({ name: "clientorderslist" });
      } else {
        this.$router.push({ name: "orderslist" });
      }
    },
  },
};
</script>

<template>
  <div>
      <WidgetLayout 
      :heading="'Halo Tickets'"
      :icon="'fa fa-ticket'"
      :showFooterButtons="isClient"
      :showFooter="isClient"
      :showPrimaryButton="false"
      :infoButtonText="'View Tickets'"
      @infoButtonEvent="viewTicket"
      @primaryButtonEvent="openNewTicketModal"
    >
        <div :class="isClient ? 'table-responsive small-table': 'table-responsive'">
            <table class="table table-hover" v-if="tickets.length > 0">
              <thead class="text-left">
                <tr>
                  <th>Ticket</th>
                  <th>Subject</th>
                  <th>Status</th>
                  <th>Created</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="ticket in tickets" :key="ticket.ticketNumber" @click="viewTicketInHalo(ticket)" v-tooltip="'View Ticket in Halo'">
                  <td>
                    <small>{{ ticket.ticketNumber }}</small>
                  </td>
                  <td>
                    <small>{{ ticket.description }}</small>
                  </td>
                  <td>
                    <span class="badge" :style="`background-color: ${ticket.colour}; color: ${isDarkColour(ticket.colour) ? '#ffffff' : '#000000'}`">{{ ticket.status }}</span>
                  </td>
                  <td class="text-nowrap">
                    <small>{{ momentAgo(ticket.created) }}</small>
                  </td>
                </tr>
              </tbody>
            </table>
            <div v-else><span>No active tickets</span></div>
          </div>
    </WidgetLayout>
  </div>
</template>

<script>
import WidgetLayout from './WidgetLayout';

export default {
  components: {
    WidgetLayout
  },
  data() {
    return {
      tickets: [],
      modalTitle: '',
    };
  },
  mounted() {
    const clientNumber = this.$route.params.clientNumber;
    this.$http
      .get(this.$config.aimsAPIv3 + "tickets/" + clientNumber)
      .then(
        (response) => {
          this.tickets = response.data;
        },
        (error) => {
          this.showError("Error fetching Halo tickets", error);
          console.error(error);
        }
      );
  },
  computed: {
    isClient() {
      return this.userContainsPermission(["CLIENT"]);
    },
  },
  methods: {
    getStatusBadge(status) {
      var badge = "badge badge-success";
      if (status == "Open") {
        badge = "badge bg-green";
      }
      if (status == "Cancelled") {
        badge = "badge bg-red text-white";
      }
      if (status == "Pending") {
        badge = "badge bg-yellow";
      }
      return badge;
    },
    viewTicketInHalo: function(ticket) {
      window.open(ticket.url);
    },
    viewTicket: function() {
      this.$router.push({
          name: 'halotickets',
          params: {
              clientNumber: this.$route.params.clientNumber
          }
      });
    },
    isDarkColour: function(bgColour) {        
        let color = (bgColour.charAt(0) === '#') ? bgColour.substring(1, 7) : bgColour;        
        let red = parseInt(color.substring(0, 2), 16);
        let green = parseInt(color.substring(2, 4), 16);
        let blue = parseInt(color.substring(4, 6), 16); 
        
        return ((red * 0.299) + (green * 0.587) + (blue * 0.114)) <= 186;
    },
    openNewTicketModal: function() {
      this.$refs.openCreateNewTicketModal.open();
      this.modalTitle = 'New Ticket';
    }
  },
};
</script>

<style scoped>
.table-responsive.small-table {
  height: 200px !important;
}
.table-responsive {
  height: 255px !important;
}
</style>
<template>
  <service-screen-layout :parts="parts">
    <template v-slot:domain>
      <div class="row">
        <div class="col-md-7">
          <div class="form-group d-flex align-items-baseline col-md-10 row-spacing">
            <label class="col-md-3">Name: </label>
            <input
              type="text"
              class="form-control-plaintext"
              disabled
              v-model="saleDefinition.definition.hosting.name"
            />
          </div>
          <div class="form-group d-flex align-items-baseline col-md-10">
            <label class="col-md-3">Minimum TTL: </label>
            <div class="input-group with-focus col-md-5">
              <input
                type="text"
                class="form-control"
                v-model="saleDefinition.definition.hosting.minimumTtl"
                :disabled="!staffOrClientAccess(productRestriction)"
              />
              <div class="input-group-append" v-if="staffOrClientAccess(productRestriction)">
                <span
                  @click="saveSale()"
                  class="
                    input-group-text
                    btn btn-info
                    text-white
                  "
                >
                  <i class="fa fa-save"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
       <div class="row row-spacing">
          <div class="col-md-12">
            <h4>Default NS Records</h4>
          </div>
        </div>
      <div class="row">
        <div class="col-md-12">
          <table class="table table-bordered">
            <thead>
              <th>Type</th>
              <th>TTL</th>
              <th>Destination</th>
            </thead>
            <tbody>
              <tr
                v-for="host in saleDefinition.definition.hosting.defaultNsHosts"
                :key="host.hostNumber"
              >
                <td>{{ host.type }}</td>
                <td>{{ host.ttl }}</td>
                <td style="word-break: break-word">{{ host.destination }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </template>
    <template v-slot:hosts>
      <HostManagement
        :saleDefinition="saleDefinition"
        :productRestriction="productRestriction"
        :showVerify="true"
        @loadSale="loadSale"
      />
    </template>
  </service-screen-layout>
</template>

<script>
import ServiceScreenLayout from "@/layouts/ServiceScreenLayout";
import Modal from "@/components/GeneralComponents/Modal";
import HostManagement from "@/components/ObjParts/MultipleCaptured/HostManagement.vue";
import SaleFunctions from "@/pages/Services/SaleFunctions";

export default {
  extends: SaleFunctions,
  components: { ServiceScreenLayout, Modal, HostManagement },
  data() {
    return {
      parts: [
        {
          name: "domain",
          icon: "fa fa-info-circle",
          displayName: "Domain",
          permissions: ["*"],
        },
        {
          name: "hosts",
          icon: "fa fa-cloud",
          displayName: "Hosts",
          permissions: ["*"],
        },
      ],
      saleNumber: this.$route.params.saleNumber,
      saleDefinition: {
        definition: {
          hosting: {
            hosts: []
          },
        },
      },
      
      productRestrictions: [],
      productRestriction: {},
    };
  },
  mounted() {
    this.loadSale();
  },
  methods: {
    loadSale() {
      const saleNumber = this.$route.params.saleNumber;
      this.getSaleDefinition(saleNumber).then(
        (response) => {
          console.log(response.data);
          this.saleDefinition = response.data;
          this.getAllProductRestrictions();          
        },
        (error) => {
          this.showError("Could not load Sale Definition", error);
          console.error();
        }
      );
    },
    
    saveSale() {
      this.$http
        .put(
          this.$config.aimsAPI +
            "sales/definition/" +
            this.saleDefinition.sale.saleNumber +
            "/update",
          this.saleDefinition
        )
        .then(
          (response) => {
            this.showSuccess("Save Success");
            this.saleDefinition = response.data;
          },
          (error) => {
            console.error(error);
            this.showError("Error Saving SaleDefinition", error);
          }
        );
    },
    getAllProductRestrictions: function() {
      const hostUrl = this.$config.aimsAPI;

      this.$http.get(`${hostUrl}products/restrictions`).then(
        (response) => {
          this.productRestrictions = response.data;

          this.productRestrictions.filter((restriction) => {
            if (restriction.description.toLowerCase() === this.saleDefinition.product.clientAccessRestriction.toLowerCase()) {
              this.productRestriction = restriction;
            }
          });
        },
        (error) => {
          console.error(error);
          this.showError("Error fetch all product restrictions", error);
        }
      );
    }
  },
};
</script>

<style scoped>
tr>th:hover {
  cursor:pointer;
}

.table>thead>tr>th {
  padding: 1em;
  background-color: #3a3f51;
  color: #FFFFFF; 

  position: sticky;
  top: 0;
  z-index: 2;
} 

.table>thead>th {
  background-color: #3a3f51;
  color: #FFFFFF; 

  position: sticky;
  top: 0;
  z-index: 2;
} 

.nsHead {
  color: royalblue;
  display: inline;
}

.terminalText{
  font-family: monospace;
  letter-spacing: -1px;
  word-spacing: 6px;
}

.icon-green {
  color: #37BC9B
}

.icon-yellow {
  color: #FF902B
}

.icon-red {
  color: #F05050
}

.wrap-text {
  width: 35%;
  word-break: break-all;
}

.pdr3 {
  padding-right: 3em;
}

.rotateEffect {
  transition-duration: 0.3s;
}

.rotateEffect:active {
   transform: rotate(180deg);
}
</style>
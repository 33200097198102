<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card card-default">
        <div class="card-header pb-0">
              <div class="row">
                <div class="col-md-4">
                  <button type="button" class="btn btn-info" @click="createNewOrder">
                    Create New Order
                  </button>
                </div>
              </div>
              <hr />
              <div class="row">
                  <div class="form-group col-md-2">
                  <label>Filter by Status</label>
                  <v-select :clearable="true" placeholder="Select Status" :options="statuses" label="description" valueProp="name" v-model="filterStatus" :disabled="loadingLines"></v-select>
                </div>
                <div class="form-group col-md-2">
                  <label>Filter by Operator</label>
                  <v-select :clearable="true" placeholder="Select Operator" :options="operators" label="description" valueProp="name" v-model="filterOperator" :disabled="loadingLines"></v-select>
                </div>
                <div class="form-group col-md-2">
                  <label>Filter by Type</label>
                  <v-select :clearable="true" placeholder="Select Type" :options="types" label="description" valueProp="name" v-model="filterType" :disabled="loadingLines"></v-select>
                </div>
                <div class="form-group col-md-4" v-if="!isClient">
                    <label>Filter by Client</label>
                    <input
                      v-if="startingClient"
                      class="form-control"
                      type="text"
                      v-model="filterClient.toString"
                      disabled
                    />
                    <ObjAutocomplete
                      v-else
                      @objSelected="clientSelected"
                      objClass="za.co.adept.aims.classes.Client"
                      :startingObject="startingClient"
                    />
                  </div>
              </div>
              <div class="row">
                <div class="col-md-12" style="text-align:center;">
                  <PageNavigator
                    :disableControls="loadingLines"
                    :currentPage="pageNumber"
                    :totalPages="totalPages-1"
                    @nextPage="nextPage"
                    @previousPage="previousPage"
                    @firstPage="firstPage"
                    @lastPage="lastPage"
                  ></PageNavigator>
                </div>
              </div>
        </div>
        <div class="card-body">

          <div class="mt-2 row-spacing table-responsive table-bordered" v-if="orderslist.length > 0 && !loadingLines" style="padding-top: 0; margin-top: 0;">
            <table class="table">
              <thead>
                <tr class="th-smaller">
                  <th class="round-left-top">Order Date</th>
                  <th>Status</th>
                  <th>Operator</th>
                  <th>Type</th>
                  <th>ISP reference</th>
                  <th>FNO reference</th>
                  <th v-if="!isClient">Client</th>
                  <th class="round-right-top">Service</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="order in orderslist" :key="order.fttxOrderNumber">
                  <td>{{ dateFormat(order.orderDate) }}</td>
                  <td>{{order.status}}</td>
                  <td>{{order.operator}}</td>
                  <td>{{order.type}}</td>
                  <td>{{order.ispReference}}</td>
                  <td style="text-align:center">
                    <span @click="viewOrder(order)" class="btn btn-link"  v-tooltip="'View order'">{{order.fnoReference}}</span>
                  </td>
                  <td v-if="!isClient">
                    <span class="btn btn-link" @click="goToClient(order.clientNumber)" v-tooltip="'View client'">{{order.clientNumber}}</span>
                  </td>
                  <td>{{order.service}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-else-if="loadingLines" style="padding: 10em;text-align: center;">
            <loading :active="true" :can-cancel="false"></loading>
          </div>
          <div class="mt-3 alert alert-info" v-else>
            <strong>No Orders found</strong>
          </div>
        </div>
      </div>
   </div>
   <FttxOrderModal
      ref="orderModal"
      :modalTitle="`${selectedOrder.operator} order [${selectedOrder.fnoReference}]`"
      :selectedOrder="selectedOrder"
      :orderTypes="types"
      :orderOperators="operators"
      :orderStatuses="statuses"
      :orderDetail="orderDetail"
      @updateOrder="updateOrder"
      @getPagedOrders="getPagedOrders"
    />
   <modal :title="'Preview'" :saveNeeded="false" ref="jsonModal" size="modal-lg">
      <div class="fontsmaller">
          <pre id="json">{{ selectedOrder.json }}</pre>
      </div>
    </modal>
  </div>
</template>

<script>
import { Circle } from "vue-loading-spinner";
import PageNavigator from '@/components/GeneralComponents/PageNavigator';
import ObjAutocomplete from "@/components/GeneralComponents/InputControls/ObjAutocomplete";
import Modal from '@/components/GeneralComponents/Modal'
import FttxOrderModal from "@/components/Admin/Modals/FttxOrderModal";

  export default {
    components: {
      loading: Circle,
      PageNavigator,
      ObjAutocomplete,
      FttxOrderModal,
      Modal
    },
    data() {
      return {
        loadingLines: true,
        ordersLoaded: false,
        orderslist: [],
        statuses: [],
        filterStatus: null,
        operators: [],
        filterOperator: null,
        types: [],
        filterType: null,
        clients: [],
        startingClient: null,
        filterClient: {},
        selectedOrder: {},
        pageNumber: 0,
        pageSize: 30,
        totalPages: 0,
        orderDetail: {},
        orderPlaced: false,
        fnoOptions: {}
      }
    },
    mounted() {
      this.getStatuses();
      this.getOperators();
      this.getTypes();
      this.getPagedOrders();
    },
    created() {
      if (this.userContainsPermission(["CLIENT"])) {
          this.$root.$emit("SET_CLIENT_PAGE_TITLE", this.$route.meta.title);
      } else {
        this.$root.$emit("SET_PAGE_TITLE", this.$route.meta.title);
        this.$root.$emit("SET_PAGE_TITLE_BADGES", []);
      }
    },
    computed: {
      isClient() {
        return this.userContainsPermission(["CLIENT"]);
      },
    },
    watch: {
        pageNumber() {
          this.getPagedOrders(false);
        },
        filterStatus() {
          this.firstPage();
          this.getPagedOrders(false);
        },
        filterType() {
          this.firstPage();
          this.getPagedOrders(false);
        },
        filterOperator() {
          this.firstPage();
          this.getPagedOrders(false);
        },
    },
    methods: {
      clientSelected(client) {
        this.filterClient.clientNumber = client.clientNumber;
        this.firstPage();
        this.getPagedOrders();
      },
      getPagedParams() {
        let clause = '?page='+(this.pageNumber+1)+"&pageSize="+this.pageSize+"&order=-orderDate&order=-fttxOrderNumber";
        if (this.filterStatus) {
            clause = clause + '&status=' + this.filterStatus;
        }
        if (this.filterOperator) {
            clause = clause + '&operator=' + this.filterOperator;
        }
        if (this.filterType) {
            clause = clause + '&type=' + this.filterType;
        }
        if (this.filterClient.clientNumber) {
            clause = clause + '&clientNumber=' + this.filterClient.clientNumber;
        }

        return clause;
      },
      firstPage() {
        this.pageNumber = 0;
      },
      nextPage(num) {
        this.pageNumber = this.pageNumber + num;
      },
      previousPage(num) {
        this.pageNumber = this.pageNumber - num;
      },
      lastPage() {
        this.pageNumber = this.totalPages-1;
      },
      getStatuses() {
        this.$http.get(this.$config.aimsAPIv3 + 'fttx/orders/statuses').then(
          response => {
            this.statuses = response.data;
          },
          error => {
            this.showError('Error fetching Status options', error);
            console.error(error)
          })
      },
      getOperators() {
        this.$http.get(this.$config.aimsAPIv3 + 'fttx/orders/operators').then(
          response => {
            this.operators = response.data;
            
            this.operators.forEach(f => {
              this.fnoOptions[f.name.toLowerCase()] =  f.description;
            });           
          },
          error => {
            this.showError('Error fetching Operator options', error);
            console.error(error)
          })
      },
      getTypes() {
        this.$http.get(this.$config.aimsAPIv3 + 'fttx/orders/types').then(
          response => {
            this.types = response.data;
          },
          error => {
            this.showError('Error fetching Type options', error);
            console.error(error)
          })
      },
      getPagedOrders(orderPlaced) {
        this.loadingLines = true;
        let pagedParams = this.getPagedParams();
        this.$http.get(this.$config.aimsAPIv3 + 'fttx/orders'+pagedParams).then(
          response => {
            this.totalPages = response.data.totalPages;
            this.orderslist = response.data.results;
            this.loadingLines = false;

            if (this.$route.params.fnoRef && (orderPlaced === undefined || orderPlaced === false)) {
                let order = this.orderslist.find(o => o.fnoReference === this.$route.params.fnoRef);
                this.viewOrder(order);
            }
          },
          error => {
            this.showError('Error fetching Orders', error);
            console.error(error);
          });
      },
      viewOrder(order) {
        this.selectedOrder = order;
        this.orderDetail = JSON.parse(order.json); 
        this.$refs.orderModal.open();
      },
      createNewOrder() {
        this.$swal({
          title: 'Select a Fibre Network Operator',
          input: "select",
          inputOptions: this.fnoOptions,
          confirmButtonColor: "#37bc9b",
          showCloseButton: true,
          padding: "2em"
        }).then((result) => {
          if (result.value) {
            const fno = result.value;
            if (this.$route.params.clientNumber) {
              this.$router.push({ name: 'clientneworder', params: { fnoName: fno } });
            } else {
              this.$router.push({ name: 'neworder', params: { fnoName: fno } });
            }
          }
        });
      },
      updateOrder(order) {
        console.log("todo");
      },
      goToClient(clientNumber) {
        this.$router.push({ path: `/main/client/${clientNumber}/dashboard` });
      }
    },
  }
</script>

<style>

    .th-smaller {
       height: 1px;
       line-height: 1px;
    }

    .fontsmaller {
      font-size: xx-small;
    }

</style>

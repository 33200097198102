import { render, staticRenderFns } from "./DomainHostingOnlyService.vue?vue&type=template&id=a6e38292&scoped=true"
import script from "./DomainHostingOnlyService.vue?vue&type=script&lang=js"
export * from "./DomainHostingOnlyService.vue?vue&type=script&lang=js"
import style0 from "./DomainHostingOnlyService.vue?vue&type=style&index=0&id=a6e38292&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a6e38292",
  null
  
)

export default component.exports
<template>
  <div>  
    <div class="row">
          <div class="col-md-6">
              <label>Description:</label>
              <input type="text" :disabled="true" class="form-control" v-model="subscription.description">
          </div>
          <div class="col-md-3">
            <label>Payment Term:</label>
            <input type="text" :disabled="true" class="form-control" v-model="subscription.paymentTerm">
          </div>
          <div class="col-md-3">
              <label>Subscription Start Date:</label>
              <div class="input-group">
                <DateInput
                  type="date"
                  v-model="subscription.startDate"
                  :disabled="true"
                  style="width: 100%"
                /> 
                <div class="input-group-append">
                    <span class="input-group-text"><i class="fa fa-calendar" aria-hidden="true"></i></span>
                </div>
              </div>
          </div>
    </div>
    <div class="row">
        <div class="col-md-12 mt-4">
            <div class="form-group row">
                <div class="col-sm-12">
                    <button class="btn btn-info" type="button" @click="addContract">Add Subscription Contract</button>
                </div>
            </div>
            <div class="form-group row">

                <div class="card col-sm-12">
                    <div class="card-body">
                        <div v-for="contract in contracts" :key="contract.subscriptionContractNumber">
                            <div class="card border-primary">
                                <div class="card-header" style="border-bottom:1px solid rgba(0, 0, 0, 0.125)">
                                    <h4>Subscription</h4>
                                    <div class="row">
                                        <div class="col-md-3">
                                            <label>Contract Start Date:</label>
                                            <DateInput
                                                id="lstart"
                                                type="date"
                                                v-model="contract.startDate"
                                                style="width: 100%"
                                                disabled
                                            />  
                                        </div>
                                        <div class="col-md-3">  
                                            <label>Contract End Date:</label>
                                            <label v-if="checkExpired(contract.endDate)" class="has-error">&nbsp;&nbsp;EXPIRED</label>
                                            <DateInput
                                                id="lend"
                                                type="date"
                                                v-model="contract.endDate"
                                                style="width: 100%"
                                                disabled
                                            /> 
                                        </div>
                                        <div class="col-md-6 mt-4">
                                            <button type="button" class="btn btn-info" @click="editLicences(contract)" :disabled="checkExpired(contract.endDate)">Add Licence</button>
                                            <button type="button" class="btn btn-primary" @click="removeContract(contract)">Cancel Subscription</button>
                                        </div>
                                    </div>

                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-12 mt-4">
                                            <h4>Licences</h4>
                                            <div class="table-responsive table-bordered">
                                                <table class="table">
                                                    <thead>
                                                        <tr>
                                                            <th class="th-smaller">Start Date</th>
                                                            <th class="th-smaller">Quantity</th>
                                                            <th class="th-smaller">Unit Cost (excl. VAT)</th>
                                                            <th class="th-smaller">Licence Key</th>
                                                            <th class="th-smaller"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody v-if="contract.licenceBundles.length > 0">
                                                        <tr v-for="licence in contract.licenceBundles" :key="licence.licenceBundleNumber">
                                                            <td>{{licence.startDate}}</td>
                                                            <td>{{licence.quantity}}</td>
                                                            <td>{{currencyFormat(licence.unitCost)}}</td>
                                                            <td>{{licence.licenceKey}}</td>
                                                            <td style="text-align:center">
                                                                <button type="button" class="btn btn-primary" @click="removeLicence(licence)"><i class="fa fa-trash"></i></button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                    <tbody v-else>
                                                        <tr>
                                                            <td colspan="4">No Licences found</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                </div>  
                            </div>      
                        </div>    
                    </div>
                </div>
            </div>

        </div>
    </div>
  </div>
</template>

<script>
    import DateInput from "@/components/GeneralComponents/InputControls/DateInput";

    export default {
        components: {
            DateInput
        },
        props: {
            subscription: {
                type: Object,
                required: true
            },
            contracts: {
                type: Array,
                required: true
            }
        },
        methods: {
            addContract: function(data) {                                
                this.$emit("addContract", data);
            },
            editLicences: function(data) {                            
                this.$emit("editLicences", data);
            },
            removeContract: function(data) {                                
                this.$emit("removeContract", data);
            },
            removeLicence: function (data) {
                this.$emit("removeLicence", data);
            },
            checkExpired: function(data) {
                return parseInt(this.getCurrentPeriod()) > parseInt(this.dateToPeriod(data));
            }
            
        }
    }
</script>

<style>

    .th-smaller {
       height: 1px;
       line-height: 1px;
    }

</style>

<template>
  <service-screen-layout
    v-if="
      saleDefinition.definition.cappedService ||
      saleDefinition.definition.uncappedService
    "
    :parts="parts"
  >
    <template v-slot:account>
      <div class="row">
        <div class="col-md-7">
          <ConnectivityPackage
            v-allowed:view="['STAFF']"
            :bundle="
              saleDefinition.definition.uncappedService.connectivityPackage
            "
          />
        </div>
      </div>
    </template>
    <template v-slot:line v-if="saleDefinition.definition.line">
        <ConnectivityLine
          v-allowed:view="['STAFF', 'CLIENT', 'RESELLER', 'SYSTEM']"
          :line="saleDefinition.definition.line"
          @saveSale="saveSale"
        />
    </template>
  </service-screen-layout>
</template>

<script>
import ServiceScreenLayout from "@/layouts/ServiceScreenLayout";
import ConnectivityPackage from "@/components/ObjParts/SingleConstructed/ConnectivityPackage";
import ConnectivityLine from "@/components/ObjParts/SingleCaptured/ConnectivityLine";
import SaleFunctions from "@/pages/Services/SaleFunctions";

export default {
  extends: SaleFunctions,
  components: {
    ServiceScreenLayout,
    ConnectivityPackage,
    ConnectivityLine,
  },
  // watch: {
  //   saleDefinition: {
  //     deep: true,
  //     handler(newSaleDef, oldSaleDef) {
  //       if (oldSaleDef.sale.description != newSaleDef.sale.description) {
  //         this.$parent.$refs.serviceTitle.reloadSale();
  //       }
  //     },
  //   },
  // },
  data: function () {
    return {
      parts: [
        {
          name: "account",
          icon: "fa fa-user-circle",
          displayName: "Account",
          permissions: ["*"],
        },
        {
          name: "line",
          icon: "fa fa-link",
          displayName: "Line",
          permissions: ["*"],
        },
      ],
      saleDefinition: {
        product: {
          classificationProvider: "",
        },
        sale: {
          created: null,
          saleNumber: null,
        },
        definition: {
        },
      },
    };
  },
  async mounted() {
    const saleNumber = this.$route.params.saleNumber;
    this.getSaleDefinition(saleNumber).then(
      (success) => {
        this.saleDefinition = success.data;
      },
      (error) => {
        console.error(error);
        this.showError("Error Fetching Sale Definition", error);
      }
    );
  },
  methods: {
    saveSale: function () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$http
            .put(
              this.$config.aimsAPI +
                "sales/definition/" +
                this.saleDefinition.sale.saleNumber +
                "/update",
              this.saleDefinition
            )
            .then(
              (response) => {
                this.showSuccess("Save Success");
                this.saleDefinition = response.data;
                this.saleDefinition.definition.pppoe.fullUsername =
                  this.saleDefinition.definition.pppoe.username +
                  "@" +
                  this.saleDefinition.definition.pppoe.pppoeRealm.name;
              },
              (error) => {
                console.error(error);
                this.showError("Error Saving Pppoe Account", error);
              }
            );
        }
      });
    },
  },
};
</script>

<template>
  <div>
    <div v-show="checkAvailability" class="check-availability-container">
      <div class="check-availability-wrapper">
        <i class="fa-solid fa-magnifying-glass-location"></i>
        <h3>Checking location availability...</h3>
      </div>
    </div>
    <div v-show="placingOrder" class="place-order-container">
      <div class="place-order-wrapper">
        <i class="fa-solid fa-truck-fast"></i>
        <h3>Placing order...</h3>
      </div>
    </div>
    <div v-show="locationFound" class="location-container">
      <div class="location-wrapper">
        <h4>{{ locations.length }} {{ locations.length > 1 ? "locations" : "location" }} found for this address</h4>
        <div class="table-responsive">
          <table class="table table-bordered" style="width: 100%;">
            <thead>
              <tr>
                <th>Location ID</th>
                <th>Address</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="location in locations" :key="location.locationId">
                <td>{{ location.locationId }}</td>
                <td>{{ location.address }}</td>
                <td class="text-center">
                  <button class="btn btn-info" @click="selectLocation(location.locationId)">Select</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <form-wizard
      title=""
      subtitle=""
      @on-complete="createOrder"
      stepSize="sm"
      color="#3a3f51"
      error-color="#e2231a"
      finishButtonText="Place order"
      v-show="(!checkAvailability || !placingOrder || !locationFound) && showForm"
    >
      <tab-content
        title="Location Details"
        icon="fa-solid fa-location-dot"
        :before-change="() => validateStep('location-details')"
      >
        <form novalidate data-vv-scope="location-details" @submit.prevent>
          <div class="col-md-12">
            <div class="row"></div>
            <div class="row">
              <div
                class="form-group col-md-12"
                :class="{
                  'has-error': errors.has('location-details.Address'),
                }"
              >
                <label>Address</label>
                <div class="input-group">
                  <gmap-autocomplete
                    id="gmap-autocomplete"
                    ref="gmapInput"
                    style="width: 100%"
                    :options="placesOptions"
                    @place_changed="setPlace"
                  >
                    <template v-slot:default="slotProps">
                      <input
                        type="text"
                        class="form-control"
                        ref="input"
                        v-on:listeners="slotProps.listeners"
                        v-on:attrs="slotProps.attrs"
                        name="Address"
                        :class="{
                          'has-error': errors.has('location-details.Address'),
                        }"
                      />
                    </template>
                  </gmap-autocomplete>
                  <div class="input-group-append">
                    <span class="input-group-text">
                      <i class="fa-solid fa-location-dot"></i>
                    </span>
                  </div>
                </div>
                <div class="d-flex align-items-center mb-0 mt-1">
                  <small class="mr-1"
                    >Press enter key after entering coordinates</small
                  >
                  <i
                    class="fa-solid fa-circle-info"
                    v-tooltip="
                      'Only applicable if you are using coordinates to get the location'
                    "
                  ></i>
                </div>
                <small
                  class="has-error mt-1"
                  v-if="errors.has('location-details.Address')"
                  >{{ errors.first("location-details.Address") }}</small
                >
              </div>
            </div>
            <div class="row row-spacing">
              <div class="col-md-12">
                <gmap-map
                  :center="mapControls.center"
                  :zoom="mapControls.zoom"
                  style="width: 100%; height: 300px"
                  :options="mapOptions"
                >
                  <GmapMarker
                    :position="mapControls.center"
                    :clickable="true"
                    :draggable="true"
                    @dragend="dragMarker($event.latLng)"
                  />
                </gmap-map>
              </div>
            </div>
          </div>
        </form>
      </tab-content>
      <tab-content
        title="End-User Details"
        icon="fa-solid fa-handshake"
        :before-change="() => validateStep('end-user-details')"
      >
        <form novalidate data-vv-scope="end-user-details">
          <div class="row">
            <div class="col-md-6">
              <div class="card mt-4 bg-green">
                <div class="card-body py-1">
                  <div class="d-flex align-items-center">
                    <i class="fa-solid fa-circle-info mr-2"></i>
                    <small class=""
                      >The information entered here should be that of the end-user
                      (the person who is living at the premises)</small
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="row" v-if="userContainsPermission(['STAFF'])">
                <div
                  class="form-group col-md-6"
                  :class="{
                    'has-error': errors.has('end-user-details.Client'),
                  }"
                >
                  <label>Client</label>
                  <v-select
                    :options="clients"
                    v-model="clientNumber"
                    valueProp="clientNumber"
                    label="toString"
                    name="Client"
                    v-validate="'required'"
                    :searchable="true"
                    :class="{
                      'has-error': errors.has('end-user-details.Client'),
                    }"
                  >
                  </v-select>
                  <small
                    class="has-error mt-1"
                    v-if="errors.has('end-user-details.Client')"
                    >{{ errors.first("end-user-details.Client") }}</small
                  >
                </div>
              </div>
              <div class="row">
                <div
                  class="form-group col-md-6"
                  :class="{
                    'has-error': errors.has('end-user-details.Firstname'),
                  }"
                >
                  <label>Firstname</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="orderObj.first_name"
                    name="Firstname"
                    placeholder="Enter a firstname"
                    v-validate="'required'"
                    :class="{
                      'has-error': errors.has('end-user-details.Firstname'),
                    }"
                  />
                  <small
                    class="has-error mt-1"
                    v-if="errors.has('end-user-details.Firstname')"
                    >{{ errors.first("end-user-details.Firstname") }}</small
                  >
                </div>
                <div
                  class="form-group col-md-6"
                  :class="{
                    'has-error': errors.has('end-user-details.Lastname'),
                  }"
                >
                  <label>Lastname</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="orderObj.last_name"
                    name="Lastname"
                    placeholder="Enter a lastname"
                    v-validate="'required'"
                    :class="{
                      'has-error': errors.has('end-user-details.Lastname'),
                    }"
                  />
                  <small
                    class="has-error mt-1"
                    v-if="errors.has('end-user-details.Lastname')"
                    >{{ errors.first("end-user-details.Lastname") }}</small
                  >
                </div>
              </div>
              <div class="row">
                <div
                  class="form-group col-md-6"
                  :class="{
                    'has-error': errors.has('end-user-details.Email'),
                  }"
                >
                  <label>Email</label>
                  <input
                    type="email"
                    class="form-control"
                    v-model="orderObj.email"
                    name="Email"
                    placeholder="Enter an email address"
                    v-validate="'required'"
                    :class="{
                      'has-error': errors.has('end-user-details.Email'),
                    }"
                  />
                  <small
                    class="has-error mt-1"
                    v-if="errors.has('end-user-details.Email')"
                    >{{ errors.first("end-user-details.Email") }}</small
                  >
                </div>
                <div
                  class="form-group col-md-6"
                  :class="{
                    'has-error': errors.has('end-user-details.Phone number'),
                  }"
                >
                  <label>Phone number</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="orderObj.cell_number"
                    name="Phone number"
                    placeholder="Enter a phone number"
                    v-validate="{ regex: /^([0-9 ]+)*$/ }"
                    :class="{
                      'has-error': errors.has('end-user-details.Phone number'),
                    }"
                    required
                  />
                  <small
                    class="has-error mt-1"
                    v-if="errors.has('end-user-details.Phone number')"
                    >{{ errors.first("end-user-details.Phone number") }}</small
                  >
                </div>
              </div>
              <div class="row">
                <div
                  class="form-group col-md-6"
                >
                  <label>Alternative Contact Name</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="orderObj.alternativecontactname"
                    name="Alt Contact Name"
                    placeholder="Enter an Alt Contact Name"
                  />
                </div>
                <div
                  class="form-group col-md-6"
                >
                  <label>Alternative Contact Number</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="orderObj.alternatecontactnumber"
                    name="Alt Contact Number"
                    placeholder="Enter an Alt Contact Number"
                  />
                </div>
              </div>
              <div class="row">
                <div
                  class="form-group col-md-6"
                >
                  <label>Alternative Contact Email</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="orderObj.alternatecontactemail"
                    name="Alt Contact Email"
                    placeholder="Enter an Alt Contact Email"
                  />
                </div>
                <div
                  class="form-group col-md-6"
                  :class="{
                    'has-error': errors.has('end-user-details.ID Number'),
                  }"
                >
                  <label>National ID Number</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="orderObj.identity_number"
                    name="ID Number"
                    placeholder="Enter a valid ID Number"
                    v-validate="{ regex: /^([0-9 ]+)*$/ }"
                    :class="{
                      'has-error': errors.has('end-user-details.ID Number'),
                    }"
                    required
                  />
                  <small
                    class="has-error mt-1"
                    v-if="errors.has('end-user-details.ID Number')"
                    >{{ errors.first("end-user-details.ID Number") }}</small
                  >
                </div>
              </div>
            </div>
          </div>
        </form>
      </tab-content>
      <tab-content
        title="Order Details"
        icon="fa-solid fa-truck-fast"
        :before-change="() => validateStep('order-details')"
      >
        <form data-vv-scope="order-details">
          <div class="col-md-6">
            <div class="row">
              <div
                class="form-group col-md-12"
                :class="{
                  'has-error': errors.has('order-details.Order number'),
                }"
              >
                <label>Order number</label>
                <i
                  class="fa-solid fa-circle-info ml-2"
                  v-tooltip="
                    'This should be the ISP order number reference (e.g ADExxx - Octotel)'
                  "
                ></i>
                <input
                  type="text"
                  class="form-control"
                  v-model="orderObj.isp_order_reference"
                  name="Order number"
                  v-validate="'required'"
                  placeholder="Enter an order number"
                  :class="{
                    'has-error': errors.has('order-details.Order number'),
                  }"
                />
                <small
                  class="has-error mt-1"
                  v-if="errors.has('order-details.Order number')"
                  >{{ errors.first("order-details.Order number") }}</small
                >
              </div>
            </div>
            <div class="row">
              <div
                class="form-group col-md-12"
                :class="{
                  'has-error': errors.has('order-details.Packages'),
                }"
              >
                <label>Packages</label>
                <select
                  class="form-control"
                  v-model="orderObj.product_reference"
                  :class="{ 'has-error': errors.has('order-details.Packages') }"
                  name="Packages"
                  v-validate="'required'"
                >
                  <option
                    v-for="pkg in packages"
                    :key="pkg.id"
                    :value="pkg.id"
                  >
                    {{ pkg.description }}
                  </option>
                </select>
                <small
                  class="has-error mt-1"
                  v-if="errors.has('order-details.Packages')"
                  >{{ errors.first("order-details.Packages") }}</small
                >
              </div>
            </div>
            <!-- Not sure where to add comments in the data -->
            <!-- <div class="row">
              <div class="form-group col-md-12">
                <label>Comment</label>
                <textarea
                  type="text"
                  class="form-control"
                  v-model="orderObj.comment"
                  name="Comment"
                  placeholder="Enter a comment"
                >
                </textarea>
              </div>
            </div> -->
          </div>
        </form>
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";

export default {
  components: {
    FormWizard,
    TabContent,
  },
  data() {
    return {
      clientNumber: null,
      clients: [],
      packages: [],
      orderObj: {
        first_name: "",
        last_name: "",
        cell_number: "",
        email: "",
        location_reference: "",
        product_reference: "",
        isp_order_reference: "",
        identity_number: null,
        alternativecontactname: "",
        alternatecontactnumber: "",
        alternatecontactemail: "",
      },
      googleMapsGeocoder: null,
      placesOptions: {
        componentRestrictions: {
          country: "za",
        },
        fields: [
          "address_components",
          "geometry",
          "icon",
          "name",
          "formatted_address",
        ],
        strictBounds: false,
      },
      mapControls: {
        zoom: 20,
        maxZoom: 12,
        center: {
          lat: 0,
          lng: 0,
        },
      },
      mapOptions: {
        mapType: "roadmap",
        streetViewControl: true,
        rotateControl: true,
        fullscreenControl: true,
      },
      locations: [],
      checkAvailability: false,
      placingOrder: false,
      showForm: true,
      locationFound: false,
    };
  },
  created() {
    this.getAllClients();
    this.getPackages();
  },
  mounted() {
    this.mapControls.center = {
        lat: -33.962806761226304,
        lng: 18.839347483267904,
    };
  },
  methods: {
    getAllClients: function () {
      const hostUrl = this.$config.aimsAPI;

      this.$http.get(`${hostUrl}clients/all`).then(
        (response) => {
          this.clients = response.data;
        },
        (error) => {
          this.showError("Error fetching all clients", error);
          console.error(error);
        }
      );
    },
    getPackages: function () {
      const hostUrl = this.$config.aimsAPIv3;

      this.$http.get(`${hostUrl}fttx/orders/vumatel/packages`).then(
        (response) => {
          this.packages = response.data.VC;
        },
        (error) => {
          this.showError("Error fetching packages", error);
          console.error(error);
        }
      );
    },
    setPlace: function (place) {
      const containsAddress = place.hasOwnProperty("formatted_address");

      if (containsAddress) {
        const addressParts = place.address_components;
        const addressCoordinates = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        };

        this.mapControls.center = new google.maps.LatLng(
          place.geometry.location.lat(),
          place.geometry.location.lng()
        );

        this.orderObj.address = place.formatted_address;
        let fullAddress = "";

        addressParts.forEach((part) => {
          if (part.types.includes("street_number")) {
            fullAddress = part.long_name;
          }

          if (part.types.includes("route")) {
            fullAddress = `${fullAddress} ${part.long_name}`;
          }
        });

        this.getLocationReference(
          addressCoordinates.lng,
          addressCoordinates.lat,
          fullAddress
        );
      } else {
        if (!this.googleMapsGeocoder) {
          this.googleMapsGeocoder = new google.maps.Geocoder();
        }

        const latlngStr = place.name.split(",", 2);
        const latlng = {
          lat: parseFloat(latlngStr[0]),
          lng: parseFloat(latlngStr[1]),
        };
        
        this.mapControls.center = new google.maps.LatLng(
          latlngStr[0],
          latlngStr[1]
        );

        this.googleMapsGeocoder.geocode(
          { location: latlng },
          function (results, status) {
            if (status === "OK" && results[4]) {
              $("#gmap-autocomplete").val(results[4].formatted_address);
              const addressParts = results[4].address_components;
              this.orderObj.address = results[4].formatted_address;

              let fullAddress = "";

              addressParts.forEach((part) => {
                if (part.types.includes("street_number")) {
                  fullAddress = part.long_name;
                }

                if (part.types.includes("route")) {
                  fullAddress = `${fullAddress} ${part.long_name}`;
                }
              });

              this.getLocationReference(latlng.lng, latlng.lat, fullAddress);
            }
          }.bind(this)
        );
      }

      if (place && place.geometry) {
        return (this.mapControls.center = place.geometry.location);
      }

      this.currentPlace = place;
    },
    getLocationReference: function (lon, lat, fullAddress) {
      const hostUrl = this.$config.aimsAPIv3;
      let query = fullAddress !== "" ? `search=${fullAddress}` : `lon=${lon}&lat=${lat}`
      this.showForm = false;
      this.checkAvailability = true;
      
      this.$http.get(`${hostUrl}fttx/orders/vumatel/availability?${query}`).then(
        (response) => {
            this.locations = response.data;

            if (this.locations.length > 0) {
              this.locationFound = true;
            } else {
              this.showWarningAlert("No fibre available in this location");
              this.showForm = true;
            }
          },
          (error) => {
            this.showError("Error fetching location reference", error);
            console.error(error);
          }
        ).finally(() => {
          this.checkAvailability = false;
        })
    },
    selectLocation: function(locationId) {
      this.orderObj.location_reference = locationId;
      this.locationFound = false;
      this.showForm = true;
    },
    validateStep: async function (stepName) {
      const result = await this.$validator.validateAll(stepName);
      if (result) {
        return true;
      } else {
        return false;
      }
    },
    createOrder: function () {
      this.orderObj.cell_number = this.orderObj.cell_number.replaceAll(" ", "");
      this.orderObj.alternatecontactnumber = this.orderObj.alternatecontactnumber.replaceAll(" ", "");
      const hostUrl = this.$config.aimsAPIv3;
      const optionalParam = this.clientNumber !== null ? `?clientNumber=${this.clientNumber}` : "";
      this.showForm = false;
      this.placingOrder = true;
      
      this.$http.post(`${hostUrl}fttx/orders/vumatel${optionalParam}`, this.orderObj).then(
          (response) => {
            const data = response.data;
            this.showSuccess("New order successfully created");

            if (this.$route.params.clientNumber) {
              this.$router.push({
                name: "clientorderslist",
                params: { fnoRef: `${data.fnoReference}` },
              });
            } else {
              this.$router.push({
                name: "orderslist",
                params: { fnoRef: `${data.fnoReference}` },
              });
            }
          },
          (error) => {
            this.showError("Error creating new order", error);
            console.error(error);
          }
        ).finally(() => {
          this.placingOrder = false;
          this.showForm = true;
        });
    },
    dragMarker: function (event) {
      if (event) {
        if (!this.googleMapsGeocoder) {
          this.googleMapsGeocoder = new google.maps.Geocoder();
        }
        const latlng = {
          lat: event.lat(),
          lng: event.lng(),
        };

        this.googleMapsGeocoder.geocode(
          { location: latlng },
          function (results, status) {
            if (status === "OK" && results[0]) {
              $("#gmap-autocomplete").val(results[0].formatted_address);
              this.$refs.gmapInput.$refs.input.value =
                results[0].formatted_address;
              const addressParts = results[0].address_components;
              this.orderObj.address = results[0].formatted_address;

              addressParts.forEach((part) => {
                if (part.types.includes("street_number")) {
                  this.orderObj.street_number = parseInt(part.long_name);
                }

                if (part.types.includes("route")) {
                  this.orderObj.street = part.long_name;
                }

                if (part.types.includes("sublocality")) {
                  this.orderObj.suburb = part.long_name;
                }

                this.orderObj.latitude = latlng.lat;
                this.orderObj.longitude = latlng.lng;
              });
            }
          }.bind(this)
        );
      }
    },
  },
};
</script>

<style scoped>
.location-container {
  color: #3a3f51;
}

.location-container > .location-wrapper {
  text-align: center;
}

.check-availability-container, .place-order-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80%;
  color: #3a3f51;
}

.check-availability-container > .check-availability-wrapper, .place-order-container > .place-order-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.check-availability-container > .check-availability-wrapper i, .place-order-container > .place-order-wrapper i {
  height: 50px;
  font-size: 4.5rem;
  margin: 30px 0;
  -webkit-animation:heartbeat 1.5s ease-in-out infinite both;
  animation:heartbeat 1.5s ease-in-out infinite both;
}

 @-webkit-keyframes heartbeat{
  from {
    -webkit-transform:scale(1);
    transform:scale(1);
    -webkit-transform-origin:center center;
    transform-origin:center center;
    -webkit-animation-timing-function:ease-out;
    animation-timing-function:ease-out
  } 10% {
    -webkit-transform:scale(.91);
    transform:scale(.91);
    -webkit-animation-timing-function:ease-in;
    animation-timing-function:ease-in 
  } 17% {
      -webkit-transform:scale(.98);
      transform:scale(.98);
      -webkit-animation-timing-function:ease-out;
      animation-timing-function:ease-out 
    } 33% {
      -webkit-transform:scale(.87);
      transform:scale(.87);
      -webkit-animation-timing-function:ease-in;
      animation-timing-function:ease-in
    } 45% {
      -webkit-transform:scale(1);
      transform:scale(1);
      -webkit-animation-timing-function:ease-out;
      animation-timing-function:ease-out
    }
  } @keyframes heartbeat {
    from {
      -webkit-transform:scale(1);
      transform:scale(1);
      -webkit-transform-origin:center center;
      transform-origin:center center;
      -webkit-animation-timing-function:ease-out;
      animation-timing-function:ease-out
    } 10% {
      -webkit-transform:scale(.91);
      transform:scale(.91);
      -webkit-animation-timing-function:ease-in;
      animation-timing-function:ease-in
    } 17% {
      -webkit-transform:scale(.98);
      transform:scale(.98);
      -webkit-animation-timing-function:ease-out;
      animation-timing-function:ease-out
    } 33% {
      -webkit-transform:scale(.87);
      transform:scale(.87);
      -webkit-animation-timing-function:ease-in;
      animation-timing-function:ease-in
    } 45% {
      -webkit-transform:scale(1);
      transform:scale(1);
      -webkit-animation-timing-function:ease-out;
      animation-timing-function:ease-out
    }
  }

</style>

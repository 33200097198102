var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.placingOrder),expression:"placingOrder"}],staticClass:"place-order-container"},[_vm._m(0)]),_c('form-wizard',{directives:[{name:"show",rawName:"v-show",value:(!_vm.placingOrder && _vm.showForm),expression:"!placingOrder && showForm"}],attrs:{"title":"","subtitle":"","stepSize":"sm","color":"#3a3f51","error-color":"#e2231a","finishButtonText":"Place order","nextButtonText":_vm.nextText},on:{"on-complete":_vm.createOrder}},[_c('tab-content',{attrs:{"title":"Location Details","icon":"fa-solid fa-location-dot","before-change":() => _vm.validateStep('location-details')}},[_c('form',{attrs:{"novalidate":"","data-vv-scope":"location-details"}},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-md-6",class:{
                'has-error': _vm.errors.has('location-details.Type'),
              }},[_c('label',[_vm._v("Type")]),_c('v-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],class:{
                  'has-error': _vm.errors.has('location-details.Type'),
                },attrs:{"options":_vm.types,"valueProp":"value","label":"description","name":"Type","placeholder":"Select a type"},model:{value:(_vm.orderObj.type),callback:function ($$v) {_vm.$set(_vm.orderObj, "type", $$v)},expression:"orderObj.type"}}),(_vm.errors.has('location-details.Type'))?_c('small',{staticClass:"has-error mt-1"},[_vm._v(_vm._s(_vm.errors.first("location-details.Type")))]):_vm._e()],1),(_vm.isBusinessType)?_c('div',{staticClass:"form-group col-md-6",class:{
                'has-error': _vm.errors.has('location-details.Business name'),
              }},[_c('label',[_vm._v("Business name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.orderObj.business_name),expression:"orderObj.business_name"},{name:"validate",rawName:"v-validate",value:(_vm.isBusinessType ? 'required' : ''),expression:"isBusinessType ? 'required' : ''"}],staticClass:"form-control",class:{
                  'has-error': _vm.errors.has('location-details.Business name'),
                },attrs:{"type":"text","name":"Business name","placeholder":"Enter a business name"},domProps:{"value":(_vm.orderObj.business_name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.orderObj, "business_name", $event.target.value)}}}),(_vm.errors.has('location-details.Business name'))?_c('small',{staticClass:"has-error mt-1"},[_vm._v(_vm._s(_vm.errors.first("location-details.Business name")))]):_vm._e()]):_vm._e(),(_vm.isApartmentType)?_c('div',{staticClass:"form-group col-md-2",class:{
                'has-error': _vm.errors.has('location-details.Apartment number'),
              }},[_c('label',[_vm._v("Apartment number")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.orderObj.apartment_number),expression:"orderObj.apartment_number"},{name:"validate",rawName:"v-validate",value:(_vm.isApartmentType ? 'required' : ''),expression:"isApartmentType ? 'required' : ''"}],staticClass:"form-control",class:{
                  'has-error': _vm.errors.has('location-details.Apartment number'),
                },attrs:{"type":"text","name":"Apartment number","placeholder":"Enter an apartment number"},domProps:{"value":(_vm.orderObj.apartment_number)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.orderObj, "apartment_number", $event.target.value)}}}),(_vm.errors.has('location-details.Apartment number'))?_c('small',{staticClass:"has-error mt-1"},[_vm._v(_vm._s(_vm.errors.first("location-details.Apartment number")))]):_vm._e()]):_vm._e(),(_vm.isApartmentType)?_c('div',{staticClass:"form-group col-md-4",class:{
                'has-error': _vm.errors.has('location-details.Building name'),
              }},[_c('label',[_vm._v("Building name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.orderObj.building_name),expression:"orderObj.building_name"},{name:"validate",rawName:"v-validate",value:(_vm.isApartmentType ? 'required' : ''),expression:"isApartmentType ? 'required' : ''"}],staticClass:"form-control",class:{
                  'has-error': _vm.errors.has('location-details.Building name'),
                },attrs:{"type":"text","name":"Building name","placeholder":"Enter a building name"},domProps:{"value":(_vm.orderObj.building_name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.orderObj, "building_name", $event.target.value)}}}),(_vm.errors.has('location-details.Building name'))?_c('small',{staticClass:"has-error mt-1"},[_vm._v(_vm._s(_vm.errors.first("location-details.Building name")))]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-md-12",class:{
                'has-error': _vm.errors.has('location-details.Address'),
              }},[_c('label',[_vm._v("Address")]),_c('div',{staticClass:"input-group"},[_c('gmap-autocomplete',{ref:"gmapInput",staticStyle:{"width":"100%"},attrs:{"id":"gmap-autocomplete","options":_vm.placesOptions},on:{"place_changed":_vm.setPlace},scopedSlots:_vm._u([{key:"default",fn:function(slotProps){return [_c('input',{ref:"input",staticClass:"form-control",class:{
                        'has-error': _vm.errors.has('location-details.Address'),
                      },attrs:{"type":"text","name":"Address"},on:{"listeners":slotProps.listeners,"attrs":slotProps.attrs}})]}}])}),_c('div',{staticClass:"input-group-append"},[_c('span',{staticClass:"input-group-text"},[_c('i',{staticClass:"fa-solid fa-location-dot"})])])],1),_c('div',{staticClass:"d-flex align-items-center mb-0 mt-1"},[_c('small',{staticClass:"mr-1"},[_vm._v("Press enter key after entering coordinates")]),_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                    'Only applicable if you are using coordinates to get the location'
                  ),expression:"\n                    'Only applicable if you are using coordinates to get the location'\n                  "}],staticClass:"fa-solid fa-circle-info"})]),(_vm.errors.has('location-details.Address'))?_c('small',{staticClass:"has-error mt-1"},[_vm._v(_vm._s(_vm.errors.first("location-details.Address")))]):_vm._e()])]),_c('div',{staticClass:"row row-spacing"},[_c('div',{staticClass:"col-md-12"},[_c('gmap-map',{staticStyle:{"width":"100%","height":"300px"},attrs:{"center":_vm.mapControls.center,"zoom":_vm.mapControls.zoom,"options":_vm.mapOptions}},[_c('GmapMarker',{attrs:{"position":_vm.mapControls.center,"clickable":true,"draggable":true},on:{"dragend":function($event){return _vm.dragMarker($event.latLng)}}})],1)],1)])])])]),_c('tab-content',{attrs:{"title":"End-User Details","icon":"fa-solid fa-handshake","before-change":() => _vm.validateStep('end-user-details')}},[_c('form',{attrs:{"novalidate":"","data-vv-scope":"end-user-details"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"card mt-4 bg-green"},[_c('div',{staticClass:"card-body py-1"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('i',{staticClass:"fa-solid fa-circle-info mr-2"}),_c('small',{},[_vm._v("The information entered here should be that of the end-user (the person who is living at the premises)")])])])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[(_vm.userContainsPermission(['STAFF']))?_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-md-12",class:{
                  'has-error': _vm.errors.has('end-user-details.Client'),
                }},[_c('label',[_vm._v("Client")]),_c('v-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],class:{
                    'has-error': _vm.errors.has('end-user-details.Client'),
                  },attrs:{"options":_vm.clients,"valueProp":"clientNumber","label":"toString","name":"Client","searchable":true},model:{value:(_vm.clientNumber),callback:function ($$v) {_vm.clientNumber=$$v},expression:"clientNumber"}}),(_vm.errors.has('end-user-details.Client'))?_c('small',{staticClass:"has-error mt-1"},[_vm._v(_vm._s(_vm.errors.first("end-user-details.Client")))]):_vm._e()],1)]):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-md-12",class:{
                  'has-error': _vm.errors.has('end-user-details.Email'),
                }},[_c('label',[_vm._v("Email")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.orderObj.customer_email),expression:"orderObj.customer_email"},{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"form-control",class:{
                    'has-error': _vm.errors.has('end-user-details.Email'),
                  },attrs:{"type":"email","name":"Email","placeholder":"Enter an email address"},domProps:{"value":(_vm.orderObj.customer_email)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.orderObj, "customer_email", $event.target.value)}}}),(_vm.errors.has('end-user-details.Email'))?_c('small',{staticClass:"has-error mt-1"},[_vm._v(_vm._s(_vm.errors.first("end-user-details.Email")))]):_vm._e()])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-md-12",class:{
                  'has-error': _vm.errors.has('end-user-details.Firstname'),
                }},[_c('label',[_vm._v("Firstname")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.orderObj.customer_first_name),expression:"orderObj.customer_first_name"},{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"form-control",class:{
                    'has-error': _vm.errors.has('end-user-details.Firstname'),
                  },attrs:{"type":"text","name":"Firstname","placeholder":"Enter a firstname"},domProps:{"value":(_vm.orderObj.customer_first_name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.orderObj, "customer_first_name", $event.target.value)}}}),(_vm.errors.has('end-user-details.Firstname'))?_c('small',{staticClass:"has-error mt-1"},[_vm._v(_vm._s(_vm.errors.first("end-user-details.Firstname")))]):_vm._e()])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-md-12",class:{
                  'has-error': _vm.errors.has('end-user-details.Lastname'),
                }},[_c('label',[_vm._v("Lastname")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.orderObj.customer_last_name),expression:"orderObj.customer_last_name"},{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"form-control",class:{
                    'has-error': _vm.errors.has('end-user-details.Lastname'),
                  },attrs:{"type":"text","name":"Lastname","placeholder":"Enter a lastname"},domProps:{"value":(_vm.orderObj.customer_last_name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.orderObj, "customer_last_name", $event.target.value)}}}),(_vm.errors.has('end-user-details.Lastname'))?_c('small',{staticClass:"has-error mt-1"},[_vm._v(_vm._s(_vm.errors.first("end-user-details.Lastname")))]):_vm._e()])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-md-12",class:{
                  'has-error': _vm.errors.has('end-user-details.Phone number'),
                }},[_c('label',[_vm._v("Phone number")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.orderObj.customer_phone),expression:"orderObj.customer_phone"},{name:"validate",rawName:"v-validate",value:({ regex: /^([0-9 ]+)*$/ }),expression:"{ regex: /^([0-9 ]+)*$/ }"}],staticClass:"form-control",class:{
                    'has-error': _vm.errors.has('end-user-details.Phone number'),
                  },attrs:{"type":"text","name":"Phone number","placeholder":"Enter a phone number","required":""},domProps:{"value":(_vm.orderObj.customer_phone)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.orderObj, "customer_phone", $event.target.value)}}}),(_vm.errors.has('end-user-details.Phone number'))?_c('small',{staticClass:"has-error mt-1"},[_vm._v(_vm._s(_vm.errors.first("end-user-details.Phone number")))]):_vm._e()])])])])])]),_c('tab-content',{attrs:{"title":"Order Details","icon":"fa-solid fa-truck-fast","before-change":() => _vm.validateStep('order-details')}},[_c('form',{attrs:{"data-vv-scope":"order-details"}},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-md-12",class:{
                'has-error': _vm.errors.has('order-details.Order number'),
              }},[_c('label',[_vm._v("Order number")]),_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                  'This should be the ISP order number reference (e.g ADExxx - Octotel)'
                ),expression:"\n                  'This should be the ISP order number reference (e.g ADExxx - Octotel)'\n                "}],staticClass:"fa-solid fa-circle-info ml-2"}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.orderObj.isp_order_number),expression:"orderObj.isp_order_number"},{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"form-control",class:{
                  'has-error': _vm.errors.has('order-details.Order number'),
                },attrs:{"type":"text","name":"Order number","placeholder":"Enter an order number"},domProps:{"value":(_vm.orderObj.isp_order_number)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.orderObj, "isp_order_number", $event.target.value)}}}),(_vm.errors.has('order-details.Order number'))?_c('small',{staticClass:"has-error mt-1"},[_vm._v(_vm._s(_vm.errors.first("order-details.Order number")))]):_vm._e()])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-md-12",class:{
                'has-error': _vm.errors.has('order-details.Packages'),
              }},[_c('label',[_vm._v("Packages")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.orderObj.service_profile_id),expression:"orderObj.service_profile_id"},{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"form-control",class:{ 'has-error': _vm.errors.has('order-details.Packages') },attrs:{"name":"Packages"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.orderObj, "service_profile_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.packageGroups),function(group,index){return _c('optgroup',{key:index,attrs:{"label":group}},_vm._l((_vm.packages[group]),function(pkg){return _c('option',{key:pkg.id,domProps:{"value":pkg.id}},[_vm._v(" "+_vm._s(pkg.description)+" ")])}),0)}),0),(_vm.errors.has('order-details.Packages'))?_c('small',{staticClass:"has-error mt-1"},[_vm._v(_vm._s(_vm.errors.first("order-details.Packages")))]):_vm._e()])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-md-12"},[_c('label',[_vm._v("Comment")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.orderObj.comment),expression:"orderObj.comment"}],staticClass:"form-control",attrs:{"type":"text","name":"Comment","placeholder":"Enter a comment"},domProps:{"value":(_vm.orderObj.comment)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.orderObj, "comment", $event.target.value)}}})])])])])])],1),_c('AddressConfirmModal',{ref:"addressConfirmModal",attrs:{"order":_vm.order,"types":_vm.types},on:{"confirmed":_vm.confirmed}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"place-order-wrapper"},[_c('i',{staticClass:"fa-solid fa-truck-fast"}),_c('h3',[_vm._v("Placing order...")])])
}]

export { render, staticRenderFns }
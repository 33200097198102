<template>
  <modal
    title="Update Prices"
    ref="priceModal"
    :size="'modal-xl'"
    :disable-save="rowPrice ? rowPrice.length == 0 : false"
    @save="savePrice()"
  >
    <div class="modal-container row col-12">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th v-if="hasSetupFee" style="min-width: 12em">Setup (excl. VAT)</th>
            <th v-if="hasSetupFeeExclVat">Setup Fee (excl. VAT)</th>
            <th v-if="hasOverUsagePrice">Over Usage Price (Per GB)</th>
            <th>Price (excl. VAT)</th>
            <th>Price (incl. VAT)</th>
            <th>Valid From</th>
            <th>Valid Until</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="price in rowPrice" :key="price.objkey">
            <td v-if="hasSetupFee">
              <div class="row">
                <div class="input-group col-12">
                  <div class="input-group-prepend">
                    <span class="input-group-text">{{ getCurrency() }}</span>
                  </div>
                  <input
                  type="number"
                  v-model="price.setupfee"
                  class="form-control"
                  />
                </div>
              </div>
            </td>
            <td v-if="hasSetupFeeExclVat">
              <div class="row">
                <div class="input-group col-12">
                <div class="input-group-prepend">
                  <span class="input-group-text">{{ getCurrency() }}</span>
                </div>
                <input
                  type="number"
                  v-model="price.setupFeeExclVat"
                  class="form-control"
                />
              </div>
              </div>
            </td>
            <td v-if="hasOverUsagePrice">
              <div class="row">
                <div class="input-group col-12">
                <div class="input-group-prepend">
                  <span class="input-group-text">{{ getCurrency() }}</span>
                </div>
                <input
                  type="number"
                  v-model="price.overUsagePrice"
                  class="form-control"
                />
              </div>
              </div>
            </td>
            <td class="wd-md">
              <div class="row">
                <div class="input-group col-12">
                <div class="input-group-prepend">
                  <span class="input-group-text">{{ getCurrency() }}</span>
                </div>
                <input
                  type="number"
                  class="form-control"
                  v-model="price.priceExclVat"
                  @input="price.priceInclVat = timesVATNoCurrency(price.priceExclVat)"
                />
              </div>
              </div>
            </td>
            <td class="wd-md">
              <div class="row">
                <div class="input-group col-12">
                <div class="input-group-prepend">
                  <span class="input-group-text">{{ getCurrency() }}</span>
                </div>
                <input
                  type="number"
                  class="form-control"
                  v-model="price.priceInclVat"
                  @input="price.priceExclVat = exVATNoCurrency(price.priceInclVat)"
                />
                </div>
              </div>
            </td>
            <td>{{ prettifyBillingPeriod(price.validFrom) }}</td>
            <td>{{ prettifyBillingPeriod(price.validUntil) }}</td>
          </tr>
          <!-- ^ TABLE OF PRICES ^ / v NEW PRICE v -->
          <tr>
            <td v-if="hasSetupFee">
              <div class="row">
                <div class="input-group col-12">
                <div class="input-group-prepend">
                  <span class="input-group-text">{{ getCurrency() }}</span>
                </div>
                <input
                  type="number"
                  v-model="newPrice.setupfee"
                  class="form-control"
                />
              </div>
              </div>
            </td>
            <td v-if="hasSetupFeeExclVat">
              <div class="row">
                <div class="input-group col-12">
                <div class="input-group-prepend">
                  <span class="input-group-text">{{ getCurrency() }}</span>
                </div>
                <input
                  type="number"
                  v-model="newPrice.setupFeeExclVat"
                  class="form-control"
                />
              </div>
              </div>
            </td>
            <td v-if="hasOverUsagePrice">
              <div class="row">
                <div class="input-group col-12">
                <div class="input-group-prepend">
                  <span class="input-group-text">{{ getCurrency() }}</span>
                </div>
                <input
                  type="number"
                  v-model="newPrice.overUsagePrice"
                  class="form-control"
                />
              </div>
              </div>
            </td>
            <td class="wd-md">
              <div class="row">
                <div class="input-group col-12">
                <div class="input-group-prepend">
                  <span class="input-group-text">{{ getCurrency() }}</span>
                </div>
                <input
                  type="number"
                  class="form-control"
                  v-model="newPrice.priceExclVat"
                  @input="newPrice.priceInclVat = timesVATNoCurrency(newPrice.priceExclVat)"
                />
              </div>
              </div>
            </td>
            <td class="wd-md">
              <div class="row">
                <div class="input-group col-12">
                <div class="input-group-prepend">
                  <span class="input-group-text">{{ getCurrency() }}</span>
                </div>
                <input
                  type="number"
                  class="form-control"
                  v-model="newPrice.priceInclVat"
                  @input="newPrice.priceExclVat = exVATNoCurrency(newPrice.priceInclVat)"
                />
              </div>
              </div>
            </td>
            <td>
              <v-select
                :options="periods"
                :get-option-label="getLabel"
                v-model="newPrice.validFrom"
                placeholder="Select Period"
              ></v-select>
            </td>
            <td>
              <button 
                :disabled="newPrice.validFrom == 0"
                type="button"
                @click="$emit('addPrice', newPrice)"
                class="btn btn-info"
              >
                Add
              </button>
              <button class="btn btn-link" type="button" @click="resetPricing()">
                Reset Unused Pricing
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </modal>
</template>

<script>
import Modal from "@/components/GeneralComponents/Modal";
import ClientFunctions from "@/pages/ClientFunctions";

export default {
  extends: ClientFunctions,
  props: {
    rowPrice: {
      type: Array,
      required: true,
    },
    newPrice: {
      type: Object,
      required: true,
    },
    currency: {
      type: String,
      required: false,
    },
  },
  computed: {
    hasSetupFee() {
      return Object.keys(this.newPrice).includes("setupfee");
    },
    hasSetupFeeExclVat() {
      return Object.keys(this.newPrice).includes("setupFeeExclVat");
    },
    hasOverUsagePrice() {
      return Object.keys(this.newPrice).includes("overUsagePrice");
    },
  },
  components: {
    Modal,
  },
  watch: {
    rowPrice(price) {
      if (price.length > 0) {
        const period = price[price.length - 1].validFrom;
        if (period >= this.getCurrentPeriod()) {
          this.getPeriods(period);
        } else {
          this.getPeriods();
        }
      } else {
        this.getPeriods();
      }
    },
  },
  data() {
    return {
      periods: [],
    };
  },
  updated() {
    this.rowPrice.forEach(price => {
        price.priceInclVat = this.timesVATNoCurrency(price.priceExclVat);
      });
  },
  methods: {
    open() {
      this.$refs.priceModal.open();
    },
    getPeriods(period) {
      let subURL = "system/billingperiods?max=12";
      if (period) {
        subURL += "&period=" + period;
      }
      this.$http.get(this.$config.aimsAPI + subURL).then(
        (response) => {
          this.periods = response.data;
        },
        (error) => {
          console.error(error);
          this.showError("Error fetching billing periods", error);
        }
      );
    },
    getLabel(period) {
      return this.prettifyBillingPeriod(period);
    },
    savePrice() {
      this.$refs.priceModal.isLoading();
      this.$http
        .post(
          this.$config.aimsAPI + "pricing/pricepackages/prices/save",
          this.rowPrice
        )
        .then(
          () => {
            this.showSuccess("Successfully Saved Prices");
            this.$emit("pricesAdded");
            this.$refs.priceModal.close();
          },
          (error) => {
            this.showError("Error Saving Pricing", error);
            console.error(error);
          }
        )
        .finally(() => {
          this.$refs.priceModal.isLoading();
        });
    },
    resetPricing() {
      this.$swal({
        title: "Reset Pricing?",
        text: "This will delete all FUTURE dated packages?",
        type: "warning",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          this.$refs.priceModal.isLoading();
          this.$http
            .post(
              this.$config.aimsAPI + "pricing/pricepackages/prices/reset",
              this.rowPrice
            )
            .then(
              () => {
                this.showSuccess("Successfully Reset Prices");
                this.$emit("pricesAdded");
                this.$refs.priceModal.close();
              },
              (error) => {
                this.showError("Error Resetting Pricing", error);
                console.error(error);
              }
            )
            .finally(() => {
              this.$refs.priceModal.isLoading();
            });
        }
      });
    },
    getCurrency() {
      if (this.currency) {
        return this.currency;
      } else return "R";
    },
  },
};
</script>

<style>
.modal-container {
  max-width: 100%;
  max-height: 80vh;
  padding: 1rem;
}

.table {
  table-layout: auto; 
  width: 100%;
}

.table th {
  white-space: nowrap;
}
</style>

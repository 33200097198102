<template v-slot:hosts>
    <div>
        <div class="row row-spacing">
          <div class="col-md-4 mb-3">
            <button type="button" @click="addHost()" class="btn btn-info" v-if="staffOrClientAccess(productRestriction)">
              Add Host Record
            </button>
          </div>
          <div class="col-md-4 mb-3">
          
          </div>
          <div class="form-group col-md-4">
            <div class="input-group">
              <input type="text" class="form-control" placeholder="Search" v-model="searchTerm">
              <div class="input-group-append">
                <span class="input-group-text"><i class="fa fa-search"></i></span>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="table-responsive table-bordered" v-if="saleDefinition.definition.hosting.hosts">
              <table class="table">
                <thead>
                  <tr>
                    <th class="text-center" v-if="staffOrClientAccess(productRestriction)" style="width: 1em;">
                      <input 
                        type="checkbox"
                        class="checkbox"
                        :checked="deleteAll"
                        @change="addRemoveToGlobal"
                      />
                    </th>
                    <th @click="sortBy('name')">Name
                      <i class="fa fa-sort-asc float-right" v-if="sortKey == 'name' && reverse"></i>
                      <i class="fa fa-sort-desc float-right" v-if="sortKey == 'name' && !reverse"></i>
                    </th>
                    <th @click="sortBy('type')">Type
                      <i class="fa fa-sort-asc float-right" v-if="sortKey == 'type' && reverse"></i>
                      <i class="fa fa-sort-desc float-right" v-if="sortKey == 'type' && !reverse"></i>
                    </th>
                    <th @click="sortBy('ttl')">TTL
                      <i class="fa fa-sort-asc float-right" v-if="sortKey == 'ttl' && reverse"></i>
                      <i class="fa fa-sort-desc float-right" v-if="sortKey == 'ttl' && !reverse"></i>
                    </th>
                    <th @click="sortBy('destination')">Destination
                      <i class="fa fa-sort-asc float-right" v-if="sortKey == 'destination' && reverse"></i>
                      <i class="fa fa-sort-desc float-right" v-if="sortKey == 'destination' && !reverse"></i>
                    </th>
                    <th @click="sortBy('addReverse')" v-if="showReverse">Has Reverse Record
                      <i class="fa fa-sort-asc float-right" v-if="sortKey == 'addReverse' && reverse"></i>
                      <i class="fa fa-sort-desc float-right" v-if="sortKey == 'addReverse' && !reverse"></i>
                    </th>
                    <th class="text-center" v-if="staffOrClientAccess(productRestriction)">
                      <TableToFile 
                        v-tooltip="'Download Excel'"
                        :small="true"
                        :fileName="'Hosts'"
                        :fieldProp="{'Name' : 'name', 'Type' : 'type', 'TTL' : 'ttl', 'Destination' : 'destination'}"
                        :dataProp="filteredHostRecords"
                        :propDisable="isDeleting"
                      />
                      <button
                        v-tooltip="'Delete Selected Hosts'"
                        type="button"
                        @click="deleteSelectedHosts()"
                        class="btn btn-sm btn-primary"
                        :disabled="!isDeleting"
                      >
                        <em class="fa fa-trash fa-fw"></em>
                      </button>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(host, index) in filteredHostRecords"
                    :key="host.hostNumber + '-' + index"
                  >
                    <td class="text-center">
                      <input 
                          type="checkbox"
                          class="checkbox"
                          :id="host.hostNumber" 
                          :checked="isRowSelected(host.hostNumber)" 
                          @change="(e) => addRemoveToDelete(e, host)"
                        />
                    </td>
                    <td>{{ host.name }}</td>
                    <td class="text-center">{{ host.type }}</td>
                    <td class="text-center">{{ host.ttl }}</td>
                    <td class="wrap-text">{{ host.destination }}</td>
                    <td class="text-center" v-if="showReverse">
                      <span :class="getBadgeColor(host)" v-if="host.type == 'A'">
                        {{ getBadgeText(host) }}
                      </span>
                    </td>
                    <td class="text-center" v-if="staffOrClientAccess(productRestriction)">
                      <button
                        v-tooltip="'Edit Host'"
                        type="button"
                        @click="editHost(host)"
                        class="btn btn-sm btn-info m-1"
                        :disabled="isDeleting"
                      >
                        <em class="fa fa-edit fa-fw"></em>
                      </button>
                      <button
                        v-if="showVerify"
                        v-tooltip="'Verify Host'"
                        type="button"
                        @click="verifyHost(host)"
                        class="btn btn-sm btn-green m-1"
                        :disabled="isDeleting"
                      >
                        <em class="fa-solid fa-eye"></em>
                      </button>
                      <button
                        v-tooltip="'Delete Host'"
                        type="button"
                        @click="deleteHost(host)"
                        class="btn btn-sm btn-primary"
                        :disabled="isDeleting"
                      >
                        <em class="fa fa-trash fa-fw"></em>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <modal
          :title="verifyTitle"
          ref="verifyModal"
          :size="host.type == 'TXT' ? 'modal-lg' : host.destination.length > 25 ? 'modal-lg' : 'modal-md'"
          :dismissNeeded="false"
          :saveText="'Close'"
          @save="$refs.verifyModal.close();"
        >
          <form>
            <div style="position:absolute; right:1em; z-index:10" v-if="isStaff">
              <button
                v-tooltip="'Switch View'"
                type="button"
                @click="switchView()"
                class="rotateEffect btn btn-sm btn-warning m-1"
              >
                <em class="fa-solid fa-repeat"></em>
              </button>
            </div>
            <div v-if="!clientView">
              <div class="terminalText">
                <div
                  class="row"
                  style="text-align:center;"
                >
                  <label class="col-md-12">
                    <div class="nsHead">dns1</div>.adept.za.net
                  </label>
                </div>
                <div class="row" style="text-align:center" v-for="(res, index) in dns1Result" :key="index+'A'">
                  <label class="col-md-12">
                    {{res.host}} {{res.ttl}} {{res.type}} {{res.data}}
                  </label>
                </div>
              </div>
              <br/>
    
              <div class="terminalText">
                <div
                  class="row"
                  style="text-align:center;"
                >
                  <label class="col-md-12">
                    <div class="nsHead">dns2</div>.adept.za.net
                  </label>
                </div>
                <div class="row" style="text-align:center" v-for="(res, index) in dns2Result" :key="index+'B'">
                  <label class="col-md-12">
                    {{res.host}} {{res.ttl}} {{res.type}} {{res.data}}
                  </label>
                </div>
              </div>
              <br/>
    
              <div class="terminalText">
                <div
                  class="row"
                  style="text-align:center;"
                >
                  <label class="col-md-12">
                    <div class="nsHead">dns3</div>.adept.za.net
                  </label>
                </div>
                <div class="row" style="text-align:center" v-for="(res, index) in dns3Result" :key="index+'C'">
                  <label class="col-md-12">
                    {{res.host}} {{res.ttl}} {{res.type}} {{res.data}}
                  </label>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="terminalText">
                <div
                  class="row"
                  style="text-align:center;"
                >
                  <label class="col-md-12">
                    <div class="nsHead">ns1</div>.adept.co.za
                  </label>
                </div>
                <div class="row" style="text-align:center" v-for="(res, index) in ns1Result" :key="index+'A'">
                  <label class="col-md-12">
                    {{res.host}} {{res.ttl}} {{res.type}} {{res.data}}
                  </label>
                </div>
              </div>
              <br/>
    
              <div class="terminalText">
                <div
                  class="row"
                  style="text-align:center;"
                >
                  <label class="col-md-12">
                    <div class="nsHead">ns2</div>.adept.co.za
                  </label>
                </div>
                <div class="row" style="text-align:center" v-for="(res, index) in ns2Result" :key="index+'B'">
                  <label class="col-md-12">
                    {{res.host}} {{res.ttl}} {{res.type}} {{res.data}}
                  </label>
                </div>
              </div>
              <br/>
    
              <div class="terminalText">
                <div
                  class="row"
                  style="text-align:center;"
                >
                  <label class="col-md-12">
                    <div class="nsHead">ns3</div>.adept.co.za
                  </label>
                </div>
                <div class="row" style="text-align:center" v-for="(res, index) in ns3Result" :key="index+'C'">
                  <label class="col-md-12">
                    {{res.host}} {{res.ttl}} {{res.type}} {{res.data}}
                  </label>
                </div>
              </div>
            </div>
          </form>
        </modal>
        <modal
          :title="modalTitle"
          ref="hostModal"
          size="modal-lg"
          @save="saveHost()"
        >
          <form>
            <div class="row">
              <div
                class="
                  form-group
                  col-md-12
                  d-flex
                  align-items-baseline
                  justify-content-center
                "
              >
                <label class="col-md-2">Name:</label>
                <div class="input-group col-md-10">
                  <input
                    placeholder="Enter Name"
                    :disabled="modalTitle.includes('Edit')"
                    type="text"
                    v-model="host.name"
                    class="form-control"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text"
                      >.{{ saleDefinition.definition.hosting.name }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div
                class="
                  form-group
                  col-md-12
                  d-flex
                  align-items-baseline
                  justify-content-center
                "
              >
                <label class="col-md-2">Type:</label>
                <v-select
                  :options="availableHostTypes"
                  ref="hostType"
                  :disabled="modalTitle.includes('Edit')"
                  placeholder="Select Type"
                  class="col-md-10"
                  v-model="host.type"
                  valueProp="name"
                  label="description"
                ></v-select>
    
              </div>
            </div>
            <div class="row">
              <div
                class="
                  form-group
                  col-md-12
                  d-flex
                  align-items-baseline
                  justify-content-center
                "
              >
                <label class="col-md-2">TTL:</label>
                <div class="col-md-10">
                  <input
                    type="number"
                    min="0"
                    placeholder="Enter TTL"
                    v-model="host.ttl"
                    class="form-control"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div
                class="
                  form-group
                  col-md-12
                  d-flex
                  align-items-baseline
                  justify-content-center
                "
              >
                <label class="col-md-2">Destination:</label>
                <div class="col-md-10">
                  <textarea
                    v-if="host.type == 'TXT' || host.type == 'NAPTR'"
                    class="form-control"
                    type="text"
                    :placeholder="dnsPlaceholder"
                    rows="6" 
                    cols="50"
                    v-model="host.destination"
                  />
                  <input
                    v-else
                    class="form-control"
                    type="text"
                    :placeholder="dnsPlaceholder"
                    v-model="host.destination"
                  />
                  <small v-if="host.type == 'TXT'"
                    >Quotations will be added automatically</small
                  >
                </div>
              </div>
            </div>
            <div class="row" v-if="host.type == 'A' && showReverse">
                <div class="form-group col-md-12 d-flex align-items-baseline justify-content-center">
                    <label class="col-md-2">Reverse Record:</label>
                    <div class="col-md-10">
                        <div class="form-group">
                            <input class="ml-2" type="checkbox" v-model="host.addReverse">
                        </div>  
                    </div>
                </div>
            </div>
          </form>
        </modal>
    </div>
</template>

<script>
import Modal from "@/components/GeneralComponents/Modal";
import TableToFile from "@/components/GeneralComponents/TableToFile";
import SaleFunctions from "@/pages/Services/SaleFunctions";

export default {
    extends: SaleFunctions,
    components: {
        Modal,
        TableToFile
    },
    props: {
        saleDefinition: {
            type: Object,
            required: true,
        },
        productRestriction: {
            type: Object,
            default: false
        },
        showVerify: {
            type: Boolean,
            default: false
        },
        showReverse: {
            type: Boolean,
            default: false,
        }

    },

    data() {
        return {
            isStaff: false,
            clientView: false, 
            host: { 
                destination: '' 
            },
            modalTitle: "",
            verifyTitle: "",
            deleteAll: false,
            deletingHosts: [],

            ns1Result: [{ data: "verifying.." }],
            ns2Result: [{ data: "verifying.." }],
            ns3Result: [{ data: "verifying.." }],
            dns1Result: [{ data: "verifying.." }],
            dns2Result: [{ data: "verifying.." }],
            dns3Result: [{ data: "verifying.." }],
            
            searchTerm: "",
            sortKey: 'type',
            reverse: false,
        }
    },
    mounted() {
        if (this.userContainsPermission(["STAFF"])) {
            this.isStaff = true;
        } else {
            this.clientView = true;
        }
    },
    computed: {
        availableHostTypes() {
            if (this.saleDefinition && this.saleDefinition.supportingEnums && this.saleDefinition.supportingEnums.Host) {
                return this.saleDefinition.supportingEnums.Host.type.filter(
                    (t) => t.name != "PTR"
                );
            } else if (this.saleDefinition && this.saleDefinition.supportingEnums && this.saleDefinition.supportingEnums.InternalHost) {
              return this.saleDefinition.supportingEnums.InternalHost.type;
            } else {
              return [];
            }
            
        },
        filteredHostRecords() {
            this.hostRecordFiltered = this.saleDefinition.definition.hosting.hosts;

            if (this.searchTerm) {
                this.hostRecordFiltered = this.hostRecordFiltered.filter((host) => 
                    host.name.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
                    host.type.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
                    host.ttl.toString().includes(this.searchTerm) ||
                    host.destination.toLowerCase().includes(this.searchTerm.toLowerCase())
                );
            }

            if (this.reverse) {
                return _.orderBy(this.hostRecordFiltered, this.sortKey, 'asc');
            } else {
                return _.orderBy(this.hostRecordFiltered, this.sortKey, 'desc');
            }
        },
        dnsPlaceholder() {
          switch (this.host.type) {
            case 'A':
              return '[IPv4 address, e.g. 192.168.1.1]';
            case 'AAAA':
              return '[IPv6 address, e.g. 2001:db8::1]';
            case 'CNAME':
              return '[alias domain, e.g. alias.example.com]';
            case 'MX':
              return '[priority, e.g. 10] [mail server, e.g. mail.example.com]';
            case 'TXT':
              return '[text data, e.g. "v=spf1 include:example.com ~all"]';
            case 'NS':
              return '[name server, e.g. ns1.example.com]';
            case 'SRV':
              return '[priority, e.g. 1] [weight, e.g. 0] [port, e.g. 443] [target, e.g. example.com]';
            case 'CAA':
              return '[flags, e.g. 0] [tag, e.g. issue] [value, e.g. example.org]';
            case 'NAPTR':
              return '[order, e.g. 100] [preference, e.g. 10] [flags, e.g. "S"] [services, e.g. "E2U+sip"] [regexp, e.g. "!^.*$!sip:user@example.com!"] [replacement, e.g. example.com]';
            default:
              return 'Enter Destination';
          }
        },
        isDeleting() {
            if (this.deletingHosts.length > 0) {
                return true;
            } else {
                return false;
            }
        },
    },
    methods: {
        isRowSelected(data){
            var isDeleting = false;

            for (let i = 0; i < this.deletingHosts.length; i++) {
                if (this.deletingHosts[i].hostNumber == data) {
                isDeleting = true;
                }
            }

            return isDeleting;
        },
        addRemoveToDelete(e, host) {
            if (e.target.checked) {
                this.deletingHosts.push(host);
            } else {
                this.deletingHosts = this.deletingHosts.filter((i) => i.hostNumber != host.hostNumber);
            }
        },
        addRemoveToGlobal(e) {
            if (e.target.checked) {
                this.deletingHosts = [];
                for (let i = 0; i < this.filteredHostRecords.length; i++) {
                    this.deletingHosts.push(this.filteredHostRecords[i]);
                }
            } else {
                this.deletingHosts = [];
            }
        },
        deleteSelectedHosts() {
            var tempText = 'Hosts: ';

            for (let i = 0; i < this.deletingHosts.length; i++) {
                tempText += this.deletingHosts[i].name + " [" + this.deletingHosts[i].type + "]";
                if (i != this.deletingHosts.length-1) {
                    tempText += ', ';
                }
            }

            this.$swal({
                title: 'Delete Selected Hosts',
                text: tempText,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#e2231a',
                cancelButtonColor: '#58b0c2',
                confirmButtonText: 'Delete'
            }).then((result) => {
                if (result.value) {
                    this.$http.post(this.$config.aimsAPI + 'sales/definition/' + this.saleNumber + '/objs/delete', this.deletingHosts).then(
                        (response) => {
                            this.$swal({
                                type: 'success',
                                title: 'Success',
                                text: "Hosts have been deleted"
                            })

                            this.deletingHosts = [];
                            this.$emit('loadSale')
                        },(error) => {
                            console.error(error);

                            this.$swal({
                                type: 'error',
                                title: 'Error',
                                text: "Couldn't delete hosts"
                            })
                        }
                    );
                }
            })
        },
        async verifyDest(nameserver, hostNumber) {
            await this.$http.get(this.$config.aimsAPI + "domains/" + nameserver + "/verify/" + hostNumber)
                .then(
                (response) => {
                    if (response.data.length > 0) {
                        if (nameserver.includes("dns")) {
                            if (nameserver.includes("1")) {
                                this.dns1Result = response.data
                            } else if (nameserver.includes("2")) {
                                this.dns2Result = response.data
                            } else if (nameserver.includes("3")) {
                                this.dns3Result = response.data
                            }
                        } else {
                            if (nameserver.includes("1")) {
                                this.ns1Result = response.data
                            } else if (nameserver.includes("2")) {
                                this.ns2Result = response.data
                            } else if (nameserver.includes("3")) {
                                this.ns3Result = response.data
                            }
                        }
                    } else {
                        if (nameserver.includes("dns")) {
                            if (nameserver.includes("1")) {
                                this.dns1Result = [{ data: "No host found" }]
                            } else if (nameserver.includes("2")) {
                                this.dns2Result = [{ data: "No host found" }]
                            } else if (nameserver.includes("3")) {
                                this.dns3Result = [{ data: "No host found" }]
                            }
                        } else {
                            if (nameserver.includes("1")) {
                                this.ns1Result = [{ data: "No host found" }]
                            } else if (nameserver.includes("2")) {
                                this.ns2Result = [{ data: "No host found" }]
                            } else if (nameserver.includes("3")) {
                                this.ns3Result = [{ data: "No host found" }]
                            }
                        }
                    }
                },
                (error) => {
                    if (nameserver.includes("dns")) { 
                        if (nameserver.includes("1")) {
                            this.dns1Result = [{ data: "Error" }]
                        } else if (nameserver.includes("2")) {
                            this.dns2Result = [{ data: "Error" }]
                        } else if (nameserver.includes("3")) {
                            this.dns3Result = [{ data: "Error" }]
                        }
                    } else {
                        if (nameserver.includes("1")) {
                            this.ns1Result = [{ data: "Error" }]
                        } else if (nameserver.includes("2")) {
                            this.ns2Result = [{ data: "Error" }]
                        } else if (nameserver.includes("3")) {
                            this.ns3Result = [{ data: "Error" }]
                        }
                    }
                }
            );
        },
        popResults(data) {
            this.ns1Result = [{ data: data }];
            this.ns2Result = [{ data: data }];
            this.ns3Result = [{ data: data }];
            this.dns1Result = [{ data: data }];
            this.dns2Result = [{ data: data }];
            this.dns3Result = [{ data: data }];
        },
        verifyHost(host) {
            this.popResults("verifying..");

            this.host = host;
            this.$refs.verifyModal.open();
            if (this.host.name.length > 0) {
                this.verifyTitle = this.host.name + "." + this.saleDefinition.definition.hosting.name + " " + host.type;
            } else {
                this.verifyTitle = this.saleDefinition.definition.hosting.name + " " + host.type;
            }

            for (let i = 1; i <= 3; i++) {
                if (!this.isStaff) {
                    this.verifyDest('ns' + i + '.adept.co.za', host.hostNumber);
                } else {
                    this.verifyDest('ns' + i + '.adept.co.za', host.hostNumber);
                    this.verifyDest('dns' + i + '.adept.za.net', host.hostNumber);
                }
            }
        },
        switchView() {
            this.clientView = !this.clientView;
        },  
        addHost() {
            this.$refs.hostType.clearSelection();
            this.modalTitle = "Add Host";
            this.host = {
                class: this.saleDefinition.supportingObjs.hosts.class,
                domainNumber: this.saleDefinition.definition.hosting.domainNumber,
                ttl: this.saleDefinition.definition.hosting.minimumTtl,
                destination: ''
            };
            this.$refs.hostModal.open();
        },
        editHost(host) {
            this.host = Object.assign({}, host);
            this.modalTitle = "Edit Host";
            this.$refs.hostModal.open();
        },
        saveHost() {
            let save = true;
            if ((this.host.type == "NS") && ((this.host.name === undefined) || (this.host.name === ""))) {
                save = false;
            }

            if (this.host.type == "TXT") {
                const firstChar = this.host.destination.substring(0, 1);
                const lastChar = this.host.destination.substring(this.host.destination.length - 1);

                if (firstChar != '"') {
                    this.host.destination = '"' + this.host.destination;
                }
                if (lastChar != '"') {
                    this.host.destination = this.host.destination + '"';
                }
            }
            
            if (save) {
                this.$refs.hostModal.isLoading();
                if (this.host.objKey === 0) {
                    this.$http.post(
                        this.$config.aimsAPI +
                        "sales/definition/" +
                        this.saleDefinition.sale.saleNumber +
                        "/obj/create",
                        this.host
                    ).then(
                        (response) => {
                            this.showSuccess(response.data.message)
                            this.$emit('loadSale')
                            this.$refs.hostModal.close();
                        },
                        (error) => {
                            console.error(error);
                            this.showError("Error Saving Host", error);
                        }
                    ).finally(() => {
                        this.$refs.hostModal.isLoading();
                    });
                } else {
                    this.$http.put(
                        this.$config.aimsAPI +
                        "sales/definition/" +
                        this.saleDefinition.sale.saleNumber +
                        "/obj/update",
                        this.host
                    )
                    .then(
                    (response) => {
                        this.showSuccess(response.data.message)
                        this.$emit('loadSale')
                        this.$refs.hostModal.close();
                    },
                    (error) => {
                        console.error(error);
                        this.showError("Error Saving Host", error);
                    }
                    )
                    .finally(() => {
                        this.$refs.hostModal.isLoading();
                    });
                }
            } else {
                this.showWarningAlert("NS host record must have Name")
            }
        },
        deleteHost(host) {
            this.$swal({
                title: "Are you sure you want to delete this host?",
                text: host.type + ": " + host.name + " " + host.destination,
                type: "warning",
                showCancelButton: true,
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes",
            }).then((result) => {
                if (result.value) {
                this.$http.post(
                    this.$config.aimsAPI +
                        "sales/definition/" +
                        this.saleDefinition.sale.saleNumber +
                        "/obj/delete/",
                        host
                    )
                    .then(
                    (response) => {
                        this.showSuccess(response.data.message);
                        this.$emit('loadSale')
                    },
                    (error) => {
                        console.error(error);
                        this.showError("Error deleting host", error);
                    }
                    );
                }
            });
        }, 
        getBadgeColor: function(document) {
          let docClass = "";

          if (document.type != 'A') {
            return "badge bg-info"
          }

          switch (document.addReverse) {
            case true:
              docClass = "badge bg-green";
              break;
            case false:
              docClass = "badge bg-danger";
              break;
            default:
              docClass = "badge bg-info";
          }

          return docClass;
        },
        getBadgeText: function(document) {
          let docText = "";

          if (document.type != 'A') {
            return "N/A"
          }

          switch (document.addReverse) {
            case true:
              docText = "TRUE";
              break;
            case false:
              docText = "FALSE";
              break;
            default:
              docText = "N/A";
          }

          return docText;
        },
        sortBy(sortKey) {
            this.reverse = (this.sortKey == sortKey) ? ! this.reverse : false;
            this.sortKey = sortKey;
        },
    }
}

</script>

<style>
.wrap-text {
    word-wrap: break-word;
    word-break: break-word;
    white-space: normal;
    overflow-wrap: break-word;
    max-width: 400px;
}
</style>

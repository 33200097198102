<template>
  <ServiceScreenLayout :parts="parts" v-if="saleLoaded === true">
    <template v-slot:service>
      <div class="row">
        <div class="col-md-8">
          <div class="form-group d-flex align-items-baseline col-md-10 row-spacing">
            <label class="col-md-3">Name: </label>
            <input
              type="text"
              class="form-control-plaintext"
              disabled
              v-model="saleDefinition.definition.hosting.name"
            />
          </div>
          <div class="form-group d-flex align-items-baseline col-md-10 row-spacing">
            <label class="col-md-3">Origin: </label>
            <input
              type="text"
              class="form-control-plaintext"
              disabled
              v-model="saleDefinition.definition.hosting.origin"
            />
          </div>
          <div class="form-group d-flex align-items-baseline col-md-10">
            <label class="col-md-3">Minimum TTL: </label>
            <div class="input-group with-focus col-md-5">
              <input
                type="text"
                class="form-control"
                v-model="saleDefinition.definition.hosting.minimumTtl"
                :disabled="!staffOrClientAccess(productRestriction)"
              />
              <div class="input-group-append" v-if="staffOrClientAccess(productRestriction)">
                <span
                  @click="saveSale()"
                  class="
                    input-group-text
                    btn btn-info
                    text-white
                  "
                >
                  <i class="fa fa-save"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 mt-3">
          <div class="card card-default" style="text-align: center;">
            <div class="card-header">
              <h4>DNS View Details</h4>
            </div>
            <div class="card-body" style="padding-top: 0%;">
              <div class="row">
                <div class="col-md-12">Name: 
                  <strong>
                    {{saleDefinition.definition.hosting.dnsView.name}}
                  </strong>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">Description: 
                  <strong>
                    {{saleDefinition.definition.hosting.dnsView.description}}
                  </strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="row row-spacing">
        <div class="col-md-12">
          <h4>Default NS Records</h4>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <table class="table table-bordered">
            <thead>
              <th>Type</th>
              <th>TTL</th>
              <th>Destination</th>
            </thead>
            <tbody>
              <tr
                v-for="host in saleDefinition.definition.hosting.defaultNsHosts"
                :key="host.internalHostNumber"
              >
                <td>{{ host.type }}</td>
                <td>{{ host.ttl }}</td>
                <td style="word-break: break-word">{{ host.destination }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </template>
    <template v-slot:hosting>
      <HostManagement
        :saleDefinition="saleDefinition"
        :productRestriction="productRestriction"
        :showReverse="true"
        @loadSale="loadSale"
      />
    </template>
  </ServiceScreenLayout>
</template>

<script>
import ServiceScreenLayout from '@/layouts/ServiceScreenLayout';
import SaleFunctions from "@/pages/Services/SaleFunctions";
import Modal from "@/components/GeneralComponents/Modal";
import HostManagement from "@/components/ObjParts/MultipleCaptured/HostManagement.vue";

export default {  
    extends: SaleFunctions,
    components: { ServiceScreenLayout, Modal, HostManagement },
    data() {
      return {
        saleNumber: null,
        saleLoaded: false,
        saleDefinition: {
          definition: {
            
          },
          selectableOptions: {
            
          },
          supportingObjs: {
            
          }
        },
        parts: [
          {
            name: "service",
            icon: "fa fa-circle-user",
            displayName: "Service",
            permissions: ["*"],
          },
          {
            name: "hosting",
            icon: "fa fa-server",
            displayName: "Hosting",
            permissions: ["*"],
          },
        ],

        productRestrictions: [],
        productRestriction: {},
      }
    },
    mounted() {
      this.getSaleNumber();
      this.loadSale();      
    },
    methods: {
      getSaleNumber: function() {
        this.saleNumber =  this.$route.params.saleNumber;
      },
      loadSale: function() {
        const hostUrl = this.$config.aimsAPI;
        const saleNumber = this.saleNumber;

        this.$http.get(`${hostUrl}sales/definition/sale/${saleNumber}`).then(
          (response) => {
            this.saleDefinition = response.data;
            this.saleLoaded = true;
            this.getAllProductRestrictions();
          },
          (error) => {
            console.error(error);
            this.showError("Error fetching Sale Definition", error);
          }
        )
      },
      getAllProductRestrictions: function() {
        const hostUrl = this.$config.aimsAPI;

        this.$http.get(`${hostUrl}products/restrictions`).then(
          (response) => {
            this.productRestrictions = response.data;

            this.productRestrictions.filter((restriction) => {
              if (restriction.description.toLowerCase() === this.saleDefinition.product.clientAccessRestriction.toLowerCase()) {
                this.productRestriction = restriction;
              }
            });
          },
          (error) => {
            console.error(error);
            this.showError("Error fetch all product restrictions", error);
          }
        );
      },
      saveSale: function() {
        const hostUrl = this.$config.aimsAPI;
        const saleNumber = this.saleNumber;

        this.$validator.validateAll().then((result) => {
          if (result) {
            this.$http.put(`${hostUrl}sales/definition/${saleNumber}/update`, this.saleDefinition).then(
              (response) => {
                this.showSuccess("Save Success");
                this.getSaleDefinition();
              },
              (error) => {
                console.error(error);
                this.showError("Error Saving Sale Definition", error);
                this.getSaleDefinition();
              }
            );
          } 
        });
      },
    },
}
</script>

<style scoped>
tr>th:hover {
  cursor:pointer;
}

.table>thead>tr>th {
  padding: 1em;
  background-color: #3a3f51;
  color: #FFFFFF; 

  position: sticky;
  top: 0;
  z-index: 2;
} 

.table>thead>th {
  background-color: #3a3f51;
  color: #FFFFFF; 

  position: sticky;
  top: 0;
  z-index: 2;
} 

.nsHead {
  color: royalblue;
  display: inline;
}

.terminalText{
  font-family: monospace;
  letter-spacing: -1px;
  word-spacing: 6px;
}

.icon-green {
  color: #37BC9B
}

.icon-yellow {
  color: #FF902B
}

.icon-red {
  color: #F05050
}

.wrap-text {
  width: 35%;
  word-break: break-all;
}

.pdr3 {
  padding-right: 3em;
}

.rotateEffect {
  transition-duration: 0.3s;
}

.rotateEffect:active {
   transform: rotate(180deg);
}

.toLeft {
  float: right;
  clear: both;
}

.center {
  text-align: center;
}
</style>

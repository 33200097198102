<template>
  <div>
    <div class="row">
        <div class="col-md-12">
            <div class="row row-spacing">
                <div class="col-md-6">
                    <label>Search Tickets</label>
                    <div class="form-group">
                        <div class="input-group">
                            <input type="text" class="form-control" placeholder="Search by Ticket Number, Description, User or Agent" v-model="searchTerm">
                            <div class="input-group-append">
                                <span class="input-group-text"><i class="fa fa-search"></i></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <label>Filter by status</label>
                    <div class="form-group">
                        <v-select :clearable="true" v-model="status" :options="statuses" label="name" valueProp="name">
                        </v-select>
                    </div>
                </div>
                <div class="col-md-3">
                    <label class="mr-1">Filter by date</label>
                    <div class="form-group">
                        <div class="input-group">
                            <input type="date" 
                                class="form-control"
                                format="YYYY-MM-DD"
                                :placeholder="'Select starting date range'"
                                style="width: 100%"
                                v-model="createdDate"
                                :min="minDate"
                                @input="getAllTickets(createdDate)">
                            <div class="input-group-append">
                                <span class="input-group-text"><i class="fa fa-calendar" aria-hidden="true"></i></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="table-responsive">
      <table class="table table-hover table-bordered">
          <thead style="font-size: 14px;">
              <tr class="dark-row">
                  <th @click="sortBy('ticketNumber')" class="sort-col" style="width: 150px;">
                      Ticket Number
                      <i class="fa fa-sort-asc float-right" v-if="sortKey == 'ticketNumber' && reverse"></i>
                      <i class="fa fa-sort-desc float-right" v-if="sortKey == 'ticketNumber' && !reverse"></i>
                  </th>
                  <th @click="sortBy('description')" class="sort-col" style="width: 480px;">
                      Description
                      <i class="fa fa-sort-asc float-right" v-if="sortKey == 'description' && reverse"></i>
                      <i class="fa fa-sort-desc float-right" v-if="sortKey == 'description' && !reverse"></i>
                  </th>
                  <th  @click="sortBy('user')" class="sort-col" style="width: 140px;">
                      User
                      <i class="fa fa-sort-asc float-right" v-if="sortKey == 'user' && reverse"></i>
                      <i class="fa fa-sort-desc float-right" v-if="sortKey == 'user' && !reverse"></i>
                  </th>
                  <th  @click="sortBy('agent')" class="sort-col" style="width: 140px;">
                      Agent
                      <i class="fa fa-sort-asc float-right" v-if="sortKey == 'agent' && reverse"></i>
                      <i class="fa fa-sort-desc float-right" v-if="sortKey == 'agent' && !reverse"></i>
                  </th>
                  <th @click="sortBy('status')" class="sort-col" style="width: 150px">
                      Status
                      <i class="fa fa-sort-asc float-right" v-if="sortKey == 'status' && reverse"></i>
                      <i class="fa fa-sort-desc float-right" v-if="sortKey == 'status' && !reverse"></i>
                  </th>
                  <th  @click="sortBy('created')" class="sort-col" style="width: 130px;">
                      Created
                      <i class="fa fa-sort-asc float-right" v-if="sortKey == 'created' && reverse"></i>
                      <i class="fa fa-sort-desc float-right" v-if="sortKey == 'created' && !reverse"></i>
                  </th>
              </tr>
          </thead>
          <tbody v-if="filteredTickets.length > 0" style="font-size: 14px;">
              <tr v-for="ticket in filteredTickets" :key="ticket.ticketNumber" @click="goToHaloTickets(ticket)" v-tooltip="'View ticket in Halo Portal'">
                  <td class="td-pointer">{{ ticket.ticketNumber }}</td>
                  <td class="td-pointer">{{ ticket.description }}</td>
                  <td class="td-pointer">{{ ticket.user }}</td>
                  <td class="td-pointer">{{ ticket.agent }}</td>
                  <td class="td-pointer text-center"><span class="badge" :style="`background-color: ${ticket.colour}; color: ${isDarkColour(ticket.colour) ? '#ffffff' : '#000000'}`">{{ ticket.status }}</span></td>
                  <td class="text-nowrap td-pointer">{{dateTimeFormat(ticket.created)}}</td>
              </tr>
          </tbody>
          <tbody v-else>
              <tr>
                  <td colspan="6">No Halo Tickets to display</td>
              </tr>
          </tbody>
      </table>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      clientNumber: null,
      ticketList: [],
      noReplyTickets: [],
      OldTickets: [],
      statuses: [],
      createdDate: '',
      minDate: '2022-11-01',
      searchTerm: '',
      filteredTicketList: [],
      status: '',
      sortKey: 'ticketNumber',
      reverse: false,
    };
  },
  mounted() {
      if (this.userContainsPermission(["CLIENT"])) {
          this.$root.$emit("SET_CLIENT_PAGE_TITLE", 'Halo Tickets'); 
      }
      this.clientNumber = this.$route.params.clientNumber;
      this.getAllTickets();
      this.getTicketStatuses();
  },
  computed: {
    filteredTickets() {
        this.filteredTicketList = this.ticketList;
        
        if (this.searchTerm) {                    
            this.filteredTicketList = this.filteredTicketList.filter((ticket) =>                       
                ticket.ticketNumber.toString().includes(this.searchTerm) ||
                ticket.description.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
                ticket.user.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
                ticket.agent.toLowerCase().includes(this.searchTerm.toLowerCase()) 
            );                
        }

        if (this.status) {
            this.filteredTicketList = this.filteredTicketList.filter((ticket) => ticket.status === this.status); 
        }

        if (this.reverse) {
            return _.orderBy(this.filteredTicketList, this.sortKey, 'asc');
        } else {
            return _.orderBy(this.filteredTicketList, this.sortKey, 'desc');
        }
    }
  },
  methods: {
    getAllTickets: function(date) {
      const hostUrl = this.$config.aimsAPIv3;
      let url = `${hostUrl}tickets/${this.clientNumber}`;
      
      if (date) {
        url = `${hostUrl}tickets/${this.clientNumber}?since=${date}`;
      }
      
      this.$http.get(url).then(
        (response) => {
          this.ticketList = response.data;       
        },
        (error) => {
          console.error(error);
          this.showError("Error fetching Halo tickets", error);
        }
      );
    },
    getTicketStatuses: function() {
      const hostUrl = this.$config.aimsAPIv3;

      this.$http.get(`${hostUrl}tickets/statuses`).then(
        (response) => {
          this.statuses = response.data;                    
        },
        (error) => {
          console.error(error);
          this.showError("Error fetching Halo ticket statuses", error);
        }
      );
    },
    updateTicketList(data) {
        this.ticketList.push(data);
    },
    sortBy(sortKey) {
        this.reverse = (this.sortKey == sortKey) ? ! this.reverse : false;
        this.sortKey = sortKey;
    },
    goToHaloTickets: function(ticket) {            
        window.open(ticket.url);
    },
    isDarkColour: function(bgColour) {        
        let color = (bgColour.charAt(0) === '#') ? bgColour.substring(1, 7) : bgColour;        
        let red = parseInt(color.substring(0, 2), 16);
        let green = parseInt(color.substring(2, 4), 16);
        let blue = parseInt(color.substring(4, 6), 16); 
        
        return ((red * 0.299) + (green * 0.587) + (blue * 0.114)) <= 186;
    }
  },
};
</script>

<style scoped>
    .badge {
        border-radius: 3px;
        padding: 8px 10px;
        width: 100%;
    }
</style>
